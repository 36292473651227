import OrderScreen from './screen';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Screen } from '../types';

export default {
  Screen: OrderScreen,
  Icon: LocalShippingIcon,
  name: 'Pedido',
  path: '/orders/:orderCode',
  root: false,
} as Screen;
