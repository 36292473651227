import React from "react";
import ContentFrame from "../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink, useParams } from "react-router-dom";
import { CustomerWithOrders, OrderSummary } from "../types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CustomerCard from "../customers/customer-card";
import { useListCustomersQuery } from "../customers/queries";
import { ORDER_STATUS_LABELS } from "../order/copy";

const useStyles = makeStyles(() =>
  createStyles({
    cardsContainer: {
      display: 'flex',
      marginRight: -24,
    },
    productCode: {
      fontWeight: 'bold',
      marginRight: 10,
    },
    totalRowCell: {
      fontWeight: 'bold',
    }
  })
);

export default function CustomerScreen() {
  const classes = useStyles();
  const { customerId } = useParams();
  const { loading, error, data } = useListCustomersQuery();

  const users: null | CustomerWithOrders[] = (loading || error) ? null : data!.customers.items;

  const customer = React.useMemo(() => (
    users && users.filter(users => users.id === customerId)[0]
  ), [ users ]);

  const orders: null | OrderSummary[] = customer && customer.orders;

  let content: React.ReactElement;
  let title = 'Cliente';

  if (loading) {
    content = (<CircularProgress />);
  } else if (error || !customer || !orders) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    title = `Cliente: ${ customer.name || customer.email || customer.phoneNumber }`;
    content = (
      <div>
        <div className={ classes.cardsContainer }>
          <CustomerCard customer={ customer }/>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Fecha
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Productos
                </TableCell>
                <TableCell>
                  Notas
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                const orderId = order.createdAt;
                const count = order.items.reduce((memo, item) => memo + item.count, 0);
                return (
                  <TableRow key={ order.createdAt }>
                    <TableCell>
                      <RouterLink to={ `/customers/${ customer.id }/orders/${ orderId }` }>
                        { order.createdAt }
                      </RouterLink>
                    </TableCell>
                    <TableCell>{ ORDER_STATUS_LABELS[ order.status ] }</TableCell>
                    <TableCell>{ count }</TableCell>
                    <TableCell>{ order.notes }</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame title={ title }>
      { content }
    </ContentFrame>
  );
}
