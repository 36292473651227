import React from 'react';
import { ContactMethod } from "@del-alto/shop-util/lib";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles(() =>
  createStyles({
    contactMethodIcon: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  })
);

export default function ContactMethodIcon({ contactMethod }: { contactMethod: ContactMethod }) {
  const classes = useStyles();

  return (
    <span className={classes.contactMethodIcon}>
      {
        (contactMethod === ContactMethod.Phone && <PhoneIcon />) ||
        (contactMethod === ContactMethod.WhatsApp && <WhatsAppIcon />) ||
        null
      }
    </span>
  )
}
