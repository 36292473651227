import React from 'react';
import { useAllCategories } from "./use-product-properties";
import ProductAttributeSetSelector, { ProductAttributeSetSelectorProps } from "./product-attribute-set-selector";

type ProductCategorySelectorProps = Omit<ProductAttributeSetSelectorProps, 'propertyExtractor'>;

export default function ProductCategoriesSelector(props: ProductCategorySelectorProps) {
  return <ProductAttributeSetSelector
    {...props}
    label={ props.label || 'Categorias' }
    propertyExtractor={ useAllCategories }
  />;
}
