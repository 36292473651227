import { Product, ProductSummary } from "../types";
import React from "react";
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

type ProductChipProps = {
  product: Product | ProductSummary;
  showStock: boolean;
  className?: string;
}

export default function ProductChip({ product, showStock, className }: ProductChipProps) {
  return (
    <Chip
      className={ className }
      label={ product.code }
      { ...(showStock ? {
        avatar: <Avatar>{ (product as Product).stock || 0 }</Avatar>
      } : null) }
    />
  )
}
