import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from "@material-ui/core/styles";

import ProgressButton from "./progress-button";
import useAlert from "../util/use-alert";
import { Grow } from "./grow";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      minWidth: 800,
    },
  }),
);

export type EditFormProps<M> = {
  model: M;
  setModel: (model: M) => void;
  readOnly: boolean;
};

export type UseFormModelType<M> = () => { model: M; setModel: (model: M) => void; isValid: boolean };

export type EditDialogProps<M> = {
  open: boolean;
  onClose: () => void;
  callback: (model: M) => Promise<unknown>;
  Form: React.ComponentType<EditFormProps<M>>;
  useFormModel: UseFormModelType<M>;
  copy: {
    title: string;
    action: string;
    cancel: string;
  };
};

export default function EditDialog<M>({  open, onClose, callback, Form, useFormModel, copy: { title, action, cancel }}: EditDialogProps<M>) {
  const classes = useStyles();
  const [ saving, setSaving ] = React.useState(false);
  const [ alert, showAlert ] = useAlert();

  const { model, isValid, setModel } = useFormModel();

  const onSave = React.useCallback(async (e: React.SyntheticEvent<unknown>) => {
    e.preventDefault();
    setSaving(true);
    try {
      await callback(model);
      setSaving(false);
      onClose();
    } catch (e) {
      showAlert({ message: e.message || e, severity: 'error' });
      setSaving(false);
    }
  }, [ setSaving, callback, onClose, model ]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent>
        <form onSubmit={onSave} className={ classes.form }>
          <Form model={model} readOnly={saving} setModel={setModel}/>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={saving}>
          { cancel }
        </Button>
        <Grow />
        <ProgressButton onClick={onSave} active={saving} label={ action } disabled={!isValid}/>
      </DialogActions>
      { alert }
    </Dialog>
  );
}
