import React, { ChangeEvent, useCallback } from 'react';
import TextField from "@material-ui/core/TextField";

export type SearchBarFilterFunction<T> = (item: T, searchTerm: string) => boolean;
export type SearchBarCompareFunction<T> = (a: T, b: T) => number;
export default function useSearchBar<T>(items: null | T[], filter: SearchBarFilterFunction<T>, compare: SearchBarCompareFunction<T>): [ React.ReactNode, null | T[] ] {
  const [ searchTerm, setSearchTerm ] = React.useState('');
  const [ searching, setSearching ] = React.useState(false);

  const onSetSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSearching(true);
  },[ setSearchTerm ]);

  React.useEffect(() => {
    if (searching) {
      setTimeout(() => {
        setSearching(false);
      }, 300);
    }
  }, [ searching ]);

  const filteredItems = React.useMemo(() => {
    if (!items) {
      return null;
    }
    const search = searchTerm.toLocaleLowerCase();
    const filteredItems = items.filter(item => filter(item, search));

    return searching ? filteredItems : filteredItems.sort(compare);
  },[ items, filter, compare, searchTerm, searching ]);

  const searchBar = (
    <TextField
      label="Search"
      variant="outlined"
      value={ searchTerm }
      onChange={ onSetSearchTerm }
    />
  );

  return [ searchBar, filteredItems ];
}
