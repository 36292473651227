import React from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export type ButtonsBarProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBar: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      alignItems: 'center'
    },
    buttonWrapper: {
      marginRight: 10,
      display: 'inline-block'
    },
  }),
);

export default function ButtonsBar({ children }: ButtonsBarProps) {
  const classes = useStyles();

  return (
    <div className={ classes.buttonBar }>
      { React.Children.map(children, (button, i) => (
        <div className={ classes.buttonWrapper} key={i}>
          { button }
        </div>
      ))}
    </div>
  );
}
