import React from "react";
import { useParams } from "react-router-dom";
import ContentFrame from "../../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIconButton, { DeleteWithoutConfirmationIconButton } from "../../components/delete-icon-button";
import CreateIcon from '@material-ui/icons/Create';
import ErrorIcon from '@material-ui/icons/Error';
import {
  createSceneTagMutation,
  deleteSceneTagMutation,
  getSceneWithTagsQuery, updateSceneTagMutation,
  useGetSceneWithTagsQuery
} from "./queries";
import FormButton from "../../components/form-button";
import {
  CREATE_SCENE_TAG_COPY,
  deleteSceneTagCopy,
  EDIT_SCENE_TAG_COPY,
} from "./copy";
import AddIcon from '@material-ui/icons/Add';
import ButtonsBar from "../../components/buttons-bar";
import { TagWithNonNullProduct, TagId, TagWithProduct } from "../../types";
import { SceneTagFormModel } from "./types";
import SceneTagForm from "./form";
import { getProductByCode } from "../../products/service";
import ImagePreview from "../../components/image-preview";
import { EditProductButton, SyncProductButton } from "../../products/screen";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ProductChip from "../../products/product-chip";
import { ProductPrice } from "../../products/product-price";
import { ProductStock } from "../../products/product-stock";

const useStyles = makeStyles(() =>
  createStyles({
    editProductButtonContainer: {
      display: 'inline-block',
      marginLeft: 5,
    },
    productPriceCell: {
      textAlign: 'right',
    },
    productChip: {
      marginRight: 10,
    },
  })
);

export default function TagsScreen() {
  const classes = useStyles();
  const { sceneId } = useParams() as { sceneId: string };
  const { loading, error, data } = useGetSceneWithTagsQuery({ sceneId });

  let content: React.ReactElement;

  const scene = data && data.scene;
  const maxIndex = Math.max(-1, ...(scene ? scene.tags.map(tag => tag.index) : []));
  let title = "Tags";

  if (loading) {
    content = (<CircularProgress />);
  } else if (error || !scene) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    title = `Tags de ${scene.label}`;
    content = (
      <div>
        <ButtonsBar>
          <CreateSceneTagButton sceneId={ sceneId } index={ maxIndex + 1 } />
        </ButtonsBar>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Producto</TableCell>
                <TableCell>Stock</TableCell>
                <TableCell className={ classes.productPriceCell }>Precio</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              { scene.tags.map(tag => tag.product ?
                ( <SceneTagRow tag={ tag as TagWithNonNullProduct }/> ) :
                ( <BrokenTagRow tag={ tag } /> )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame title={ title }>
      { content }
    </ContentFrame>
  );
}

function BrokenTagRow({ tag }: { tag: TagWithProduct }) {
  return (
    <TableRow key={ tag.index }>
      <TableCell colSpan={ 4 }>
        <ErrorIcon />
      </TableCell>
      <TableCell>
        <DeleteSceneTagWithoutConfirmationButton tag={ tag } />
      </TableCell>
    </TableRow>
  );
}
function SceneTagRow({ tag }: { tag: TagWithNonNullProduct }) {
  const classes = useStyles();
  return (
    <TableRow key={ tag.index }>
      <TableCell>
        <ImagePreview uri={ tag.product.uri }/>
      </TableCell>
      <TableCell>
        <ProductChip product={ tag.product } showStock={false} className={classes.productChip}/>
        { tag.product.name }
        <div className={classes.editProductButtonContainer}>
          <EditProductButton product={ tag.product } size='small'/>
        </div>
      </TableCell>
      <TableCell><ProductStock product={tag.product} /></TableCell>
      <TableCell className={ classes.productPriceCell }><ProductPrice product={tag.product} /></TableCell>
      <TableCell>
        <EditSceneTagButton tag={ tag } />
        <SyncProductButton product={ tag.product } />
        <DeleteSceneTagButton tag={ tag } />
      </TableCell>
    </TableRow>
  );
}

const buildRefetchQueries = (sceneId: string) => [{
  query: getSceneWithTagsQuery,
  variables: {
    sceneId
  },
}];

function CreateSceneTagButton(tag: TagId) {
  return (
    <FormButton<SceneTagFormModel>
      Form={ SceneTagForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({ id: tag, geometry: null, productCode: '' } as SceneTagFormModel);

        return { model, setModel, isValid: !!model.productCode };
      }}
      mutation={ createSceneTagMutation }
      buildVariables={ async ({ productCode, geometry }) => ({
        sceneTag: {
          ...tag,
          productId: (await getProductByCode(productCode)).id,
          geometry,
        }
      })}
      refetchQueries={ buildRefetchQueries(tag.sceneId) }
      copy={ CREATE_SCENE_TAG_COPY }
      iconButton={ false }
      icon={ <AddIcon/> }
    />
  )
}

function EditSceneTagButton({ tag }: { tag: TagWithNonNullProduct }) {
  return (
    <FormButton<SceneTagFormModel>
      Form={ SceneTagForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({ id: { sceneId: tag.sceneId, index: tag.index }, geometry: tag.geometry, productCode: tag.product.code });

        return { model, setModel, isValid: !!model.productCode };
      }}
      mutation={ updateSceneTagMutation }
      buildVariables={ async ({ productCode, geometry }) => ({
        sceneTag: {
          sceneId: tag.sceneId,
          index: tag.index,
          productId: (await getProductByCode(productCode)).id,
          geometry
        }
      })}
      refetchQueries={ buildRefetchQueries(tag.sceneId) }
      copy={ EDIT_SCENE_TAG_COPY }
      iconButton={ true }
      icon={ <CreateIcon/> }
    />
  )
}

function DeleteSceneTagButton({ tag }: { tag: TagWithNonNullProduct }) {
  const refetchQueries = React.useMemo(
    () => buildRefetchQueries(tag.sceneId),
    [ tag.sceneId ]
  );

  const variables = React.useMemo(
    () => ({ sceneTag: { sceneId: tag.sceneId, index: tag.index }}),
    [ tag ],
  );

  const copy = React.useMemo(
    () => deleteSceneTagCopy(tag),
    [ tag ]
  );

  return (
    <DeleteIconButton
      mutation={ deleteSceneTagMutation }
      variables={ variables }
      refetchQueries={ refetchQueries }
      copy={ copy }
    />
  );
}

function DeleteSceneTagWithoutConfirmationButton({ tag }: { tag: TagWithProduct }) {
  const refetchQueries = React.useMemo(
    () => buildRefetchQueries(tag.sceneId),
    [ tag.sceneId ]
  );

  const variables = React.useMemo(
    () => ({ sceneTag: { sceneId: tag.sceneId, index: tag.index }}),
    [ tag ],
  );

  return (
    <DeleteWithoutConfirmationIconButton
      mutation={ deleteSceneTagMutation }
      variables={ variables }
      refetchQueries={ refetchQueries }
    />
  );
}
