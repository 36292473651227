import React from "react";
import { OperationVariables } from "@apollo/react-common";
import { useMutation, MutationHookOptions } from "@apollo/react-hooks";

import Button from "@material-ui/core/Button";

import { DocumentNode } from "graphql";

import { useToggle } from "@del-alto/shop-util";
import EditDialog, { EditFormProps, UseFormModelType } from "./edit-dialog";
import IconButton from "@material-ui/core/IconButton";
import { ButtonProps, mergeButtonProps } from "./button-props";

type FormButtonProps<TModel, TData = any, TVariables = OperationVariables> = {
  Form: React.ComponentType<EditFormProps<TModel>>;
  useFormModel: UseFormModelType<TModel>;
  mutation: DocumentNode;
  buildVariables: (model: TModel) => TVariables;
  iconButton: boolean;
  icon: React.ReactNode;
  copy: {
    title: string;
    label?: string;
    action: string;
    cancel: string;
  };
  buttonProps?: ButtonProps;
} & Pick<MutationHookOptions<TData, TVariables>, 'update' | 'refetchQueries'>;

export default function FormButton<TModel, TData = any, TVariables = OperationVariables>({ mutation, Form, refetchQueries, update, copy, buildVariables, useFormModel, iconButton, icon, buttonProps }: FormButtonProps<TModel, TData, TVariables>) {
  const [  formOpen,  onOpenForm ,  onCloseForm ] = useToggle();
  const mergedButtonProps = mergeButtonProps(buttonProps);

  const [ runAction ] = useMutation(mutation, {
    refetchQueries,
    update,
    awaitRefetchQueries: true,
  });

  const onAction = React.useCallback(
    async (model: TModel) => runAction({ variables: await buildVariables(model) }),
    [ runAction, buildVariables ],
  );

  const button = iconButton ? (
    <IconButton
      { ...mergedButtonProps }
      onClick={ onOpenForm }
    >
      { icon }
    </IconButton>
  ) : (
    <Button
      { ...mergedButtonProps }
      startIcon={ icon }
      onClick={onOpenForm}
    >
      { copy.label || copy.action }
    </Button>
  );

  return (
    <React.Fragment>
      { formOpen ?
        <EditDialog<TModel>
          open={formOpen}
          onClose={onCloseForm}
          callback={ onAction }
          Form={ Form }
          copy={ copy }
          useFormModel={useFormModel}
        />
      : null }
      { button }
    </React.Fragment>
  );
}
