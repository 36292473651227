import 'typeface-roboto';
import { isHotSale } from "@del-alto/shop-util";

import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: isHotSale() ? '#DF3738' : '#8c6a58',
    },
    secondary: {
      main: '#c5b19a',
    },
  },
});

// Primary
// bf9278
// P — Light
// #f3c2a7
// P — Dark
// #8d644c
// Secondary
// #d7ccc8
// S — Light
// #fffffb
// S — Dark
// #a69b97
