import * as React from 'react';
import Auth from './auth';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ApolloClient } from 'apollo-client';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';

import theme from "@del-alto/mui-theme";

import Routes from './routes';
import getApolloClient from "./apollo-client";
import { reducer } from './application';

const middleware = [ thunk ];

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { createLogger } = require('redux-logger');
  middleware.push(createLogger());
}

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(...middleware),
  ),
);

export default function Root() {
  const [ apolloClient, setApolloClient ] = React.useState(null as ApolloClient<unknown> | null);

  React.useEffect(() => { (async () => {
    await Auth.initialize();
    setApolloClient(await getApolloClient());
  })() }, []);

  return apolloClient ? (
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <Provider store={store}>
        <ApolloProvider client={ apolloClient }>
          <Routes/>
        </ApolloProvider>
      </Provider>
    </MuiThemeProvider>
  ) : null;
}
