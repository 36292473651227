import React  from "react";
import { useListProductsQuery } from "./queries";
import { Product, ProductSummary } from "../types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ProductChip from "./product-chip";
import SearchBox from "../components/search-box";

const useStyles = makeStyles(() =>
  createStyles({
    productChip: {
      marginRight: 10,
    },
  })
);

const stringify = (product: Product) => [product.code, product.name].join(' ');

type ProductsSearchProps = ProductsSearchWithStockProps | ProductsSearchWithoutStockProps;
type ProductsSearchBaseProps = {
  onSelectProduct: (product: Product) => void;
  label: string;
  disabled: boolean;
  autoFocus: true;
}
type ProductsSearchWithStockProps = ProductsSearchBaseProps & {
  value: Product | null;
  showStock: true;
}
type ProductsSearchWithoutStockProps = ProductsSearchBaseProps & {
  value: ProductSummary | null;
  showStock: false;
}

export default function ProductSearch({ value, onSelectProduct, label, disabled, autoFocus, showStock }: ProductsSearchProps) {
  const classes = useStyles();
  const { data } = useListProductsQuery();

  const products = React.useMemo(() => (
    data && data.products.items || []
  ), [ data ]);

  return (
    <SearchBox<ProductSummary>
      label={ label }
      onSelect={ onSelectProduct }
      value={ value }
      disabled={ disabled }
      autoFocus={ autoFocus }
      options={ products }
      getOptionLabel={(option) => option.code}
      renderOption={(product) => (
        <React.Fragment>
          <ProductChip
            product={product}
            className={classes.productChip}
            showStock={showStock}
          />
          { product.name }
        </React.Fragment>
      )}
      stringify={ stringify }
    />
  );
}
