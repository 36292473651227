export const ORIGIN = window.location.origin;

export const CLIENT_ID = '5dd8onunt95kk3vnefm31garbj';

export const AWS_REGION = 'us-east-1';

export const AUTH_DOMAIN = 'https://delalto.auth.us-east-1.amazoncognito.com';

export const REDIRECT_URI = ORIGIN;

export const AWS_COGNITO_CODE_QUERY_PARAM = 'code';

export const AWS_IDENTITY_POOL_ID = `${AWS_REGION}:7ffc8fe2-3e68-4235-a2c6-00a8b25c5e6a`;

export const AWS_BUCKET_REGION = 'sa-east-1';
export const AWS_STATIC_BUCKET_NAME = 'delalto-static';
export const AWS_STATIC_DISTRIBUTION_URL = `https://static.delaltodigital.com/`;
