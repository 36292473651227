import { Product, UpdateProductInput } from "../types";
import { getStockBehaviour, ProductTag, StockBehaviour, STOCK_BEHAVIOUR_TAGS } from "@del-alto/shop-util";
import { BulkUpdateProductInput } from "@del-alto/lambda-backend/lib/delalto-persistence-api/types";

export type ProductAttributes = 'name' | 'description' | 'sale' | 'hotSale' | 'enabled' | 'stockBehaviour' | 'class' | 'keywords' | 'categories';
export type BulkProductAttributes = 'sale' | 'hotSale' | 'enabled' | 'stockBehaviour' | 'class' | 'addKeywords' | 'removeKeywords' | 'addCategories' | 'removeCategories';
export enum BulkAvailability {
  Available,
  Unavailable,
  Mixed,
}

export type CreateProductFormModel = {
  code: string;
}

export type BulkEditProductFormModel = {
  sale: true | false | null;
  hotSale: true | false | null;
  enabled: true | false | null;
  stockBehaviour: StockBehaviour | null;
  class: string;
  addKeywords: string[];
  removeKeywords: string[];
  addCategories: string[];
  removeCategories: string[];
  available: BulkAvailability;
}

export type EditProductFormModel = {
  name: string;
  description: string;
  sale: boolean;
  hotSale: boolean;
  enabled: boolean;
  stockBehaviour: StockBehaviour;
  class: string;
  keywords: string[];
  categories: string[];
  available: boolean;
}

function getStockBehaviourTag(stockBehaviour: StockBehaviour): ProductTag | null {
  return stockBehaviour !== 'normal' ? stockBehaviour : null
}

export function getBulkAvailability(products: Product[]): BulkAvailability {
  const [ allAvailable, allUnavailable ] = products.reduce(([available, unavailable], product: Product) => {
    return [
      available && product.stock > 0,
      unavailable && product.stock === 0,
    ]
  }, [ true, true ]);
  if (allUnavailable === allAvailable) {
    return BulkAvailability.Mixed;
  }
  return allAvailable ? BulkAvailability.Available : BulkAvailability.Unavailable;
}

export function toFormModel({ name, description, tags, class: clazz, keywords, categories, stock, enabled }: Product): EditProductFormModel {
  return {
    name,
    description,
    sale: (tags || []).includes(ProductTag.Sale),
    hotSale: (tags || []).includes(ProductTag.HotSale),
    stockBehaviour: getStockBehaviour(tags),
    class: clazz,
    keywords: keywords || [],
    categories: categories || [],
    enabled: enabled !== false,
    available: stock > 0,
  };
}

export function toPersistentModel(id: string, formValues: EditProductFormModel): UpdateProductInput {
  return {
    id,
    name: formValues.name,
    description: formValues.description,
    enabled: formValues.enabled,
    keywords: formValues.keywords,
    categories: formValues.categories,
    class: formValues.class,
    tags: [
      formValues.sale ? ProductTag.Sale : null,
      formValues.hotSale ? ProductTag.HotSale : null,
      getStockBehaviourTag(formValues.stockBehaviour)
    ].filter(Boolean) as ProductTag[],
  };
}

export function toBulkEditModel(ids: string[], formValues: BulkEditProductFormModel): BulkUpdateProductInput {
  const addTags = [] as ProductTag[];
  const removeTags = [] as ProductTag[];
  const {
    class: clazz,
    enabled,
    addKeywords,
    removeKeywords,
    addCategories,
    removeCategories,
  } = formValues;

  if (formValues.sale !== null) {
    if (formValues.sale) {
      addTags.push(ProductTag.Sale);
    } else {
      removeTags.push(ProductTag.Sale);
    }
  }

  if (formValues.hotSale !== null) {
    if (formValues.hotSale) {
      addTags.push(ProductTag.HotSale);
    } else {
      removeTags.push(ProductTag.HotSale);
    }
  }

  if (formValues.stockBehaviour !== null) {
    removeTags.push(...STOCK_BEHAVIOUR_TAGS.filter(tag => tag !== formValues.stockBehaviour));
    if (formValues.stockBehaviour !== 'normal') {
      addTags.push(formValues.stockBehaviour);
    }
  }

  return {
    ids,
    ...(enabled !== null ? { enabled: enabled } : {}),
    ...(clazz ? { class: clazz } : {}),
    ...(addTags.length ? { addTags } : {}),
    ...(removeTags.length ? { removeTags } : {}),
    ...(addKeywords.length ? { addKeywords } : {}),
    ...(removeKeywords.length ? { removeKeywords } : {}),
    ...(addCategories.length ? { addCategories } : {}),
    ...(removeCategories.length ? { removeCategories } : {}),
  };
}
