import React, { ChangeEvent, useCallback } from "react";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from "@material-ui/core/CircularProgress";
import { DocumentNode } from "graphql";
import getApolloClient from "../apollo-client";
import useAlert from "../util/use-alert";
import { ButtonProps, mergeButtonProps } from "./button-props";
import { OperationVariables } from "@apollo/react-common";
import { MutationHookOptions } from "@apollo/react-hooks";
import uploadImage from "../upload-image";

type BulkUploadButtonProps<TData = any, TVariables = OperationVariables, TUpdateFileVariables = OperationVariables> = {
  inputId: string;
  findByName?: (fileName: string) => Promise<TData | null>;
  createMutation: DocumentNode;
  updateFileMutation: DocumentNode;
  buildCreateVariables: (fileName: string) => Promise<TVariables>;
  buildUpdateFileVariables: (creationData: TData, fileName: string, uri: string) => Promise<TUpdateFileVariables>;
  buildKey: (creationData: TData, fileName: string) => string;
  copy: {
    action: string;
  };
  buttonProps?: ButtonProps;
} & Pick<MutationHookOptions<TData, TVariables>, 'update' | 'refetchQueries'>;

export default function BulkUploadButton<
  TData = any,
  TVariables = OperationVariables,
  TUpdateFileVariables = OperationVariables
>(props: BulkUploadButtonProps<TData, TVariables, TUpdateFileVariables>) {
  const { inputId, buttonProps, copy: { action } } = props;
  const [ uploading, setUploading ] = React.useState(false);
  const [ alert, showAlert ] = useAlert();
  const mergedButtonProps = mergeButtonProps(buttonProps);

  const onUpload = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files!;
    if (!files.length) {
      return;
    }

    const filesArray: File[] = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files[i]);
    }

    setUploading(true);

    try {
      await bulkUpload(filesArray, props);
    } catch (e) {
      showAlert({ message: e.message || e, severity: 'error' })
    }

    setUploading(false);
  }, [
    setUploading,
    props.createMutation,
    props.updateFileMutation,
    props.buildCreateVariables,
    props.buildUpdateFileVariables,
    props.buildKey,
    props.refetchQueries,
    props.update,
  ]);

  return (
    <React.Fragment>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={ inputId }
        multiple
        type="file"
        onChange={onUpload}
      />
      <label htmlFor={ inputId }>
        <Button
          { ...mergedButtonProps }
          component="span"
          disabled={ uploading }
          endIcon={ uploading ? <CircularProgress size={20} /> : <CloudUploadIcon /> }
        >
          { action }
        </Button>
      </label>
      { alert }
    </React.Fragment>
  );
}

async function bulkUpload<
  TData = any,
  TVariables = OperationVariables,
  TUpdateFileVariables = OperationVariables
>(files: File[], { buildKey, findByName, createMutation, buildCreateVariables, buildUpdateFileVariables, updateFileMutation, refetchQueries, update }: BulkUploadButtonProps<TData, TVariables, TUpdateFileVariables>) {
  return files.reduce((chain, file) => chain.then(async () => {
    const name = file.name;

    const existing = findByName ? await findByName(name) : null;

    const apolloClient = await getApolloClient();
    const data = existing || (await apolloClient.mutate<TData, TVariables>({
      mutation: createMutation,
      variables: await buildCreateVariables(name),
      refetchQueries,
      update,
    })).data!;

    const key = buildKey(data, name);
    const extension = name.split('.').pop()!;

    const uri = await uploadImage({ key, extension, file });

    await apolloClient.mutate<TData, TUpdateFileVariables>({
      mutation: updateFileMutation,
      variables: await buildUpdateFileVariables(data, name, uri),
    });
  }), Promise.resolve());
}
