import React from "react";
import ContentFrame from "../components/content-frame";
import { useToggle } from "@del-alto/shop-util";
import useAlert from "../util/use-alert";
import ProgressButton from "../components/progress-button";
import SearchIcon from '@material-ui/icons/Search';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InstagramIcon from '@material-ui/icons/Instagram';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import { useRefreshPricesAndStockMutation, useUpdateBackendIPMutation, useUpdateSearchIndexMutation } from "./queries";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useExportCatalog from "./export-catalog";

export default function IntegrationsScreen() {
  return (
    <ContentFrame title="Integraciones">
      <UpdateSearchIndexButton />
      <RefreshPricesAndStock />
      <ExportCatalog />
      <UpdateBackendIP />
    </ContentFrame>
  );
}

function UpdateSearchIndexButton() {
  return <ActionButton
    icon={ <SearchIcon /> }
    label="Sincronizar Búsqueda"
    useMutation={ useUpdateSearchIndexMutation }
  />
}

function RefreshPricesAndStock() {
  return <ActionButton
    icon={ <AttachMoneyIcon /> }
    label="Actualizar precios y stock"
    useMutation={ useRefreshPricesAndStockMutation }
  />
}

function ExportCatalog() {
  return <ActionButton
    icon={ <InstagramIcon /> }
    label="Descargar catalogo"
    useMutation={ useExportCatalog }
  />
}

function UpdateBackendIP() {
  return <ActionButton
    icon={ <SettingsInputHdmiIcon /> }
    label="Actualizar IP"
    useMutation={ useUpdateBackendIPMutation }
  />
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginBottom: theme.spacing(3),
    },
  }),
);

type ActionButtonProps = {
  label: string;
  icon?: React.ReactNode;
  useMutation: () => [ () => void, { loading: boolean } ];
}
function ActionButton({ icon, label, useMutation }: ActionButtonProps) {
  const classes = useStyles();
  const [ mutation, { loading } ] = useMutation();
  const [ fetching, startFetching, stopFetching ] = useToggle();
  const [ alert, showAlert ] = useAlert();

  const onRunAction = React.useCallback(async () => {
    startFetching();
    try {
      // noinspection JSIgnoredPromiseFromCall
      await mutation();
    } catch(e) {
      showAlert({ message: e.message || e, severity: 'error' });
    }
    stopFetching();
  }, [ mutation, showAlert, stopFetching, startFetching ]);

  return (
    <div className={classes.actionButton}>
      <ProgressButton
        variant="contained"
        active={ loading || fetching }
        icon={ icon }
        label={ label }
        onClick={ onRunAction }
      />
      { alert }
    </div>
  );
}
