import PlaylistsScreen from './screen';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { Screen } from '../types';
export default {
  Screen: PlaylistsScreen,
  Icon: PlaylistPlayIcon,
  name: 'Playlists',
  path: '/playlists',
  root: true,
} as Screen;

export { default as contents } from './contents'
