import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Customer } from "../types";
import { CustomerInfo } from "@del-alto/shop-util/lib";
import ContactMethodIcon from "./contact-method";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      margin: '0 24px 24px 0',
    },
    title: {
      fontSize: 14,
    },
  })
);

export default function CustomerCard({ customer }: { customer: Customer }) {
  const classes = useStyles();
  const {
    name,
    familyName,
    givenName,
    email,
    phoneNumber,
  } = customer;
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          { name || email || phoneNumber }
        </Typography>
        <Typography variant="body2" component="p">
          Nombre: { givenName }
          <br/>
          Apellido: { familyName }
          <br/>
          Email: { email }
          <br/>
          Teléfono: { phoneNumber }
        </Typography>
      </CardContent>
    </Card>
  );
}

export function CustomerInfoCard({ customer }: { customer: CustomerInfo }) {
  const classes = useStyles();
  const {
    name,
    phoneNumber,
    contactMethod,
  } = customer;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          { name }
        </Typography>
        <Typography variant="body2" component="p">
          Teléfono:
          <ContactMethodIcon contactMethod={ contactMethod }/>
          { phoneNumber }
        </Typography>
      </CardContent>
    </Card>
  );
}
