import React from "react";
import { Product } from "../types";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { formatPrice, isOnSale, isOnHotSale, getFinalPrice } from "@del-alto/shop-util";

const useStyles = makeStyles(() =>
  createStyles({
    saleChip: {
      display: 'flex',
      marginTop: 5,
    },
    hotSaleChip: {
      backgroundColor: '#e01f26',
      color: 'white',
      display: 'flex',
      marginTop: 5,
    },
  }),
);

export function ProductPrice({ product }: { product: Product }) {
  const classes = useStyles();
  const finalPrice = getFinalPrice(product.price, product.price, product.tags, true);

  return (
    <React.Fragment>
      <span>
        { product.price ?
          `${formatPrice(product.price)} (${formatPrice(finalPrice)})` :
          '-'
        }
      </span>
      { isOnHotSale(product.tags) ? (
        <Chip label="HotSale (-30%)" size="small" className={ classes.hotSaleChip }/>
      ) : null}
      { isOnSale(product.tags) ? (
        <Chip label="Sale (-20%)" size="small" className={ classes.saleChip }/>
      ) : null}
    </React.Fragment>
  );
}
