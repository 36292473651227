import gql from 'graphql-tag';
import { Product, ProductSummary, UpdateProductInput } from "../types";
import { useMutation } from "@apollo/react-hooks";
import useFullScanQuery from "../util/use-full-scan-query";

export const productFragment = gql`
  fragment ProductFragment on Product {
    id
    name
    uri
    description
    code
    price
    stock
    tags
    class,
    keywords,
    categories,
    enabled,
  }
`;

export type ListProductsQueryData = {
  products: {
    items: Product[];
    nextToken?: string;
    __typename: 'ProductConnection';
  };
}
export const listProductsQuery =  gql`
  query listProducts($nextToken: String) {
    products: listProducts(nextToken: $nextToken) {
      items {
        ...ProductFragment
      }
      nextToken
    }
  }
  ${ productFragment }
`;

export const deleteProductMutation = gql`
  mutation deleteProduct($product: DeleteProductInput!) {
    product: deleteProduct(input: $product) {
      id
    }
  }`;

export type UpdateProductMutationVariables = {
  product: UpdateProductInput;
}
export const updateProductMutation = gql`
  mutation updateProduct($product: UpdateProductInput!) {
    product: updateProduct(input: $product) {
      ...ProductFragment
    }
  }
  ${ productFragment }
`;

export const bulkUpdateProductMutation = gql`
  mutation bulkUpdateProducts($input: BulkUpdateProductInput!) {
    items: bulkUpdateProducts(input: $input) {
      ...ProductFragment
    }
  }
  ${ productFragment }
`;

export const createProductMutation = gql`
  mutation createProduct($product: CreateProductInput!) {
    product: createProduct(input: $product) {
      ...ProductFragment
    }
  }
  ${ productFragment }
`;

export const getProductsInfo = gql`
  query getProductsInfo($codes: [String!]!) {
    productsInfo: getProductsInfo(codes: $codes) {
      code
      name
      description
      alias
      provider
      price
      stock
    }
  }`;

export type FindProductByCodeQueryData = { products: ProductSummary[] }
export type FindProductByCodeQueryVariables = { code: string }
export const findProductByCodeQuery = gql`
  query findProductByCodeQuery($code: String!) {
    products: findProductByCode(code: $code) {
      id
      code
      name
      uri
    }
  }`;

export const useListProductsQuery = () => useFullScanQuery<'products', 'ProductConnection', Product, ListProductsQueryData>('products', 'ProductConnection', listProductsQuery);
export const useUpdateProductMutation = () => useMutation<{}, UpdateProductMutationVariables>(updateProductMutation);
