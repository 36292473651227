import React from "react";
import ContentFrame from "../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  useFindCustomerOrderByCodeQuery,
  useSetOrderItemsMutation,
  useSetOrderNotesMutation,
  useSetOrderStatusMutation
} from "./queries";
import { useParams } from "react-router-dom";
import { OrderItem, OrderItemInput, OrderStatus, OrderWithCustomer, Product } from "../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CustomerInfoCard } from "../customers/customer-card";
import OrderCard from "./order-card";
import ImagePreview from "../components/image-preview";
import Button from "@material-ui/core/Button";
import ProductSearch from "../products/product-search";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { CustomerInfo } from "@del-alto/shop-util/lib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsContainer: {
      display: 'flex',
      marginRight: -24,
    },
    productCode: {
      fontWeight: 'bold',
      marginRight: 10,
    },
    totalRowCell: {
      fontWeight: 'bold',
    },
    grow: {
      flexGrow: 1,
    },
    buttonBar: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      width: '100%',
    },
    itemButton: {},
    orderItemCount: {
      display: 'inline-block',
    },
  })
);

export default function OrderScreen() {
  const classes = useStyles();
  const { orderCode } = useParams();
  const { loading, error, data } = useFindCustomerOrderByCodeQuery({
    code: orderCode || null,
  });

  const [ setOrderStatus ] = useSetOrderStatusMutation();
  const [ setOrderNotes ] = useSetOrderNotesMutation();
  const [ setOrderItems ] = useSetOrderItemsMutation();

  const order: null | OrderWithCustomer = (loading || error) ? null : data!.order;
  const customer: null | CustomerInfo = order && order.customerInfo;

  const setStatus = React.useCallback(async (status: OrderStatus) => {
    order && await setOrderStatus({
      variables: {
        customerId: order.customerId,
        input: {
          createdAt: order.createdAt,
          status,
        },
      },
    });
  }, [ order, setOrderStatus ]);

  const onSaveNotes = React.useCallback(async (notes: string) => {
    order && await setOrderNotes({
      variables: {
        customerId: order.customerId,
        input: {
          createdAt: order.createdAt,
          notes,
        },
      },
    });
  }, [ order, setOrderNotes ]);


  const { onAddOrderItem, onRemoveOrderItem, onSetOrderItemCount } = React.useMemo(() => {
    if (!order) {
      return {};
    }

    const updateOrderItems = (items: OrderItemInput[]) => {
      return setOrderItems({
        variables: {
          customerId: order.customerId,
          input: {
            createdAt: order.createdAt,
            items,
          },
        },
      });
    };

    const orderItemsInput: OrderItemInput[] = order.items.map(item => ({
      productId: item.product.id,
      count: item.count,
    }));

    return {
      onAddOrderItem: (product: Product) => {
        const alreadyPresent = orderItemsInput.reduce(
          (found, item) => found || item.productId === product.id,
          false,
        );

        if (!alreadyPresent) {
          // noinspection JSIgnoredPromiseFromCall
          updateOrderItems([
              ...orderItemsInput,
              {
                productId: product.id,
                count: 1,
              },
            ],
          );
        }
      },
      onRemoveOrderItem: (productId: string) => updateOrderItems(
        orderItemsInput.filter(item => item.productId !== productId),
      ),
      onSetOrderItemCount: (productId: string, count: number) => updateOrderItems(
        orderItemsInput.map(item => item.productId === productId ? {
          productId,
          count,
        } : item),
      ),
    };
  }, [ order, setOrderItems ]);

  const isDone = !!order && (order.status === OrderStatus.Canceled || order.status === OrderStatus.Completed);

  let content: React.ReactElement;
  let title = "Pedido";
  if (loading) {
    content = (<CircularProgress />);
  } else if (error || !order || !customer) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    const total = order.items.reduce((memo, { product, count }) => memo + count * product.price, 0);
    title = `Pedido de ${( customer.name )}`;
    content = (
      <div>
        <div className={ classes.cardsContainer }>
          <CustomerInfoCard customer={ customer }/>
          <OrderCard
            order={ order }
            onSaveNotes={ onSaveNotes }
            actions={(
              <React.Fragment>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => setStatus(OrderStatus.Canceled)}
                  disabled={ isDone }
                >
                  Cancelar
                </Button>
                <div className={classes.grow} />
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => setStatus(OrderStatus.Confirmed)}
                  disabled={ order.status !== OrderStatus.Review }
                >
                  Confirmar
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant={ order.status === OrderStatus.Confirmed ? 'contained' : 'outlined' }
                  onClick={() => setStatus(OrderStatus.Completed)}
                  disabled={ isDone }
                >
                  Completar
                </Button>
              </React.Fragment>
            )}
          />
        </div>
        <div className={classes.buttonBar}>
          <ProductSearch
            value={ null }
            onSelectProduct={ onAddOrderItem! }
            label="Agregar producto"
            showStock={true}
            disabled={isDone}
            autoFocus={true}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Producto
                </TableCell>
                <TableCell>
                  Imagen
                </TableCell>
                <TableCell>
                  Cantidad
                </TableCell>
                <TableCell>
                  Stock
                </TableCell>
                <TableCell>
                  Precio Unitario
                </TableCell>
                <TableCell>
                  Precio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items.map(item => (
                <OrderItemRow
                  item={ item }
                  key={ item.product.id }
                  setOrderItemCount={ onSetOrderItemCount! }
                  removeOrderItem={ onRemoveOrderItem! }
                  isDone={isDone}
                />
              ))}
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.totalRowCell}>Total</TableCell>
                <TableCell className={classes.totalRowCell}>{ total }</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame title={ title }>
      { content }
    </ContentFrame>
  );
}

type OrderItemRowProps = {
  item: OrderItem;
  setOrderItemCount: (productId: string, count: number) => void;
  removeOrderItem: (productId: string) => void;
  isDone: boolean;
}
function OrderItemRow({ item: { product, count }, setOrderItemCount, removeOrderItem, isDone }: OrderItemRowProps) {
  const classes = useStyles();

  const onIncreaseProductCount = React.useCallback(async () => {
    setOrderItemCount(product.id, count + 1);
  }, [ setOrderItemCount, count, product.id ]);

  const onDecreaseProductCount = React.useCallback(async () => {
    if (count === 0) {
      removeOrderItem(product.id);
    } else {
      setOrderItemCount(product.id, count - 1);
    }
  }, [ setOrderItemCount, removeOrderItem, count, product.id ]);

  return (
    <TableRow>
      <TableCell component="th">
        <span className={ classes.productCode }>{ product.code}</span>
        { product.name }
      </TableCell>
      <TableCell>
        <ImagePreview uri={ product.uri } />
      </TableCell>
      <TableCell component="th">
        <IconButton onClick={ onDecreaseProductCount } className={ classes.itemButton } disabled={isDone}>
          { count === 0 ? <HighlightOffRoundedIcon/> : <RemoveCircleOutlineRoundedIcon/> }
        </IconButton>
        <div className={ classes.orderItemCount }>{ count }</div>
        <IconButton onClick={ onIncreaseProductCount } className={ classes.itemButton } disabled={isDone}>
          <AddCircleOutlineRoundedIcon />
        </IconButton>
      </TableCell>
      <TableCell component="th">{ product.stock }</TableCell>
      <TableCell component="th">{ product.price }</TableCell>
      <TableCell component="th">{ product.price * count }</TableCell>
    </TableRow>
  );
}
