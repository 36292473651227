import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ContentFrame from "../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonsBar from "../components/buttons-bar";
import { PlaylistFormModel } from "./types";
import {
  createPlaylistMutation, listAllPlaylistsQuery,
  useListAllPlaylistsQuery,
} from "./queries";
import FormButton from "../components/form-button";
import PlaylistForm from "./form";
import {
  CREATE_PLAYLIST_COPY,
} from "./copy";
import AddIcon from '@material-ui/icons/Add';
import { Playlist, ProductSummary, Scene } from "../types";
import IconButton from "@material-ui/core/IconButton";
import ImagePreview from "../components/image-preview";
import useSearchBar, { SearchBarCompareFunction, SearchBarFilterFunction } from "../util/use-search-bar";
import useInfiniteScrolling from "../util/use-infinite-scrolling";
import CountCell from "../components/count-cell";
import Link from "@material-ui/core/Link";
import LaunchIcon from '@material-ui/icons/Launch';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const searchPlaylist: SearchBarFilterFunction<Playlist> = (playlist: Playlist, searchTerm: string) => {
  return playlist.label.toLocaleLowerCase().indexOf(searchTerm) !== -1;
};

const playlistSortFn: SearchBarCompareFunction<Playlist> = (a: Playlist, b: Playlist) => b.label.localeCompare(a.label);

const useStyles = makeStyles(() =>
  createStyles({
    itemPreview: {
      marginRight: 5,
    },
  }),
);

export default function PlaylistsScreen() {
  const classes = useStyles();
  const { loading, error, data } = useListAllPlaylistsQuery();

  const allPlaylists: null | Playlist[] = (loading || error) ? null : data!.playlists.items!;

  // @ts-ignore
  window.allPlaylists = allPlaylists;

  const [ searchBar, filteredPlaylists ] = useSearchBar(
    allPlaylists,
    searchPlaylist,
    playlistSortFn,
  );

  const [ sentinel, playlists, buffered ] = useInfiniteScrolling(filteredPlaylists, 'td');

  let content: React.ReactElement;
  let actions: React.ReactElement[] | null = null;

  if (loading) {
    content = (<CircularProgress />);
  } else if (error) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
      actions = [
        <CreatePlaylistButton key='create' />,
      ];
      content = (
      <div>
        <ButtonsBar>
          { searchBar }
        </ButtonsBar>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell>
                  Contenido
                </TableCell>
                <CountCell items={ filteredPlaylists } />
              </TableRow>
            </TableHead>
            <TableBody>
              { (playlists || []).map((playlist: Playlist) => (
                <TableRow key={ playlist.id }>
                  <TableCell>
                    <Link href={ generateShopPlaylistLink(playlist.id) } target="_blank">
                      { playlist.label }
                    </Link>
                  </TableCell>
                  <TableCell>{ (playlist.items || []).map((item, i) => {
                    const typedItem: Scene | ProductSummary | null =
                      (item.type === 'scene' && item.scene) ||
                      (item.type === 'product' && item.product) ||
                      null;
                    return typedItem ? (
                      <ImagePreview uri={ typedItem.uri } key={ i }  className={classes.itemPreview} type={ item.type }/>
                    ) : null;
                  })}</TableCell>
                  <TableCell>
                    <RouterLink to={ `/playlists/${ playlist.id }` }>
                      <IconButton>
                        <LaunchIcon />
                      </IconButton>
                    </RouterLink>
                  </TableCell>
                </TableRow>
              )) }
              <tr style={{ height: 81 * buffered }}>{ sentinel }</tr>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame title="Playlists" actions={actions}>
      { content }
    </ContentFrame>
  );
}

function generateShopPlaylistLink(id: string) {
  return `https://www.delaltodigital.com/pl/${id}`;
}

const refetchQueries = [{ query: listAllPlaylistsQuery }];

function CreatePlaylistButton() {
  return (
    <FormButton<PlaylistFormModel>
      Form={ PlaylistForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({ label: '' });

        return { model, setModel, isValid: !!model.label };
      }}
      mutation={ createPlaylistMutation }
      buildVariables={ async playlist => ({ playlist }) }
      refetchQueries={ refetchQueries }
      copy={ CREATE_PLAYLIST_COPY }
      iconButton={ false }
      icon={ <AddIcon/> }
      buttonProps={{ color: 'default' }}
    />
  )
}
