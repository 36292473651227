import React, { useCallback } from "react";
import { EditFormProps } from "../../components/edit-dialog";
import { SceneTagFormModel } from "./types";
import Button from "@material-ui/core/Button";
import useAlert from "../../util/use-alert";
import { useToggle } from "@del-alto/shop-util";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Geometry, Product, ProductSummary, Scene } from "../../types";
import GeometrySelector from "../../components/geometry-selector";
import { getScene } from "../service";
import ProductSearch from "../../products/product-search";
import { getProductByCode } from "../../products/service";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

type SceneTagFormProps = EditFormProps<SceneTagFormModel>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productSearchWrapper: {
      marginBottom: theme.spacing(3),
    },
  }),
);

export default function SceneTagForm({ model, setModel, readOnly }: SceneTagFormProps) {
  const classes = useStyles();
  const [ alert, showAlert ] = useAlert();
  const [ loadingScene, startLoadingScene, stopLoadingScene ] = useToggle();
  const [ product, setProduct  ] = React.useState(null as ProductSummary | null);
  const [ scene, setScene  ] = React.useState(null as Scene | null);

  React.useEffect(() => {
    if (model.productCode && (!product || model.productCode !== product.code)) {
      (async () => {
        const selectedProduct = await getProductByCode(model.productCode);
        if (selectedProduct && selectedProduct.code === model.productCode) {
          setProduct(selectedProduct);
        }
      })();
    }
  }, [ model.productCode ]);

  const onSelectProduct = useCallback((selection: Product) => {
    setProduct(selection);
    setModel({ ...model, productCode: selection.code });
  }, [ setModel, model ]);

  const onSetGeometry = useCallback((geometry: Geometry | null) => {
    setModel({ ...model, geometry });
  }, [ setModel, model ]);

  const onUnsetGeometry = useCallback(() => {
    setModel({ ...model, geometry: null });
  }, [ setModel, model ]);

  React.useEffect(() => { (async () => {
    startLoadingScene();
    try {
      setScene(await getScene(model.id.sceneId));
    } catch (e) {
      showAlert({
        message: e.message || e,
        severity: "error"
      });
    }
    stopLoadingScene();
  })() }, [ model.id ]);

  return (
    <React.Fragment>
      <div className={classes.productSearchWrapper}>
        <ProductSearch
          value={product}
          onSelectProduct={onSelectProduct}
          label="Producto"
          showStock={false}
          disabled={readOnly}
          autoFocus
        />
      </div>
      { loadingScene ? (
        <CircularProgress size={ 20 }/>
      ) : scene ? (
        <React.Fragment>
          <GeometrySelector
            src={ scene.uri }
            value={ model.geometry }
            onChange={ onSetGeometry }
          />
          <Button
            color="secondary"
            disabled={ !model.geometry }
            onClick={ onUnsetGeometry }
          >
            Borrar
          </Button>
        </React.Fragment>
      ) : null }

      { alert }

    </React.Fragment>
  )
}
