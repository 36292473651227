import { Scene } from "../types";

export const deleteSceneCopy = (scene: Scene) => ({
  cancel: 'Cancelar',
  action: 'Borrar',
  title: 'Borrar Escena',
  message: `Esta seguro que desea borrar la escena "${ scene.label }"?`,
});

export const CREATE_SCENE_COPY = {
  title: 'Crear Escena',
  action: 'Crear',
  cancel: 'Cancelar',
};

export const EDIT_SCENE_COPY = {
  title: 'Editar Escena',
  action: 'Guardar',
  cancel: 'Cancelar',
};

export const UPLOAD_SCENE_IMAGE_COPY = {
  action: 'Subir',
};

export const BULK_UPLOAD_SCENE_IMAGES_COPY = {
  action: 'Subir',
};
