import { TagWithNonNullProduct } from "../../types";

export const deleteSceneTagCopy = (tag: TagWithNonNullProduct) => ({
  cancel: 'Cancelar',
  action: 'Borrar',
  title: 'Borrar Tag',
  message: `Esta seguro que desea borrar el tag del producto "${ tag.product.name }"?`,
});

export const CREATE_SCENE_TAG_COPY = {
  title: 'Crear Tag',
  action: 'Crear',
  cancel: 'Cancelar',
};

export const EDIT_SCENE_TAG_COPY = {
  title: 'Editar Tag',
  action: 'Guardar',
  cancel: 'Cancelar',
};
