import getApolloClient from "../apollo-client";
import { getSceneQuery, GetSceneQueryData, GetSceneQueryVariables } from "./queries";
import { Scene } from "../types";

export async function getScene(id: string): Promise<Scene> {
  const apolloClient = await getApolloClient();
  const { data } = await apolloClient.query<GetSceneQueryData, GetSceneQueryVariables>({
    query: getSceneQuery,
    variables: { id },
  });

  const scene = data.scene;

  if (!scene) {
    throw new Error(`Scene with id ${ scene } not found.`)
  }

  return scene;
}
