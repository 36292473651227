import React from 'react';
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useListProductsQuery } from "./queries";
import { useAllClasses } from "./use-product-properties";

type ProductClassSelectorProps = {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  variant?: TextFieldProps["variant"];
  margin?: TextFieldProps["margin"];
}

const NEW_ENTRY_REGEXP = /^Nueva: "(.*)"$/;

const filter = createFilterOptions<string>({
  trim: true,
  ignoreAccents: true,
  ignoreCase: true,
});

export default function ProductClassSelector({ value, onChange, disabled, variant = "standard", margin = "normal" }: ProductClassSelectorProps) {

  const { loading, error, data } = useListProductsQuery();

  const allProducts = (loading || error || !data) ? null : data.products.items;

  const allClasses = useAllClasses(allProducts);

  const filterOptions = React.useCallback((options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '' && !allClasses.includes(params.inputValue)) {
      filtered.push(`Nueva: "${params.inputValue}"`);
    }

    return filtered;
  }, []);

  const onSelectOption = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const newMatch = value && value.match(NEW_ENTRY_REGEXP);
    onChange(newMatch ? newMatch[1] : value);
  },[ onChange ]);

  return (
    <Autocomplete
      selectOnFocus
      options={ allClasses }
      style={{ flex: 1 }}
      filterOptions={ filterOptions }
      onChange={ onSelectOption }
      value={ value }
      multiple={ false }
      freeSolo={ true }
      disableClearable={ true }
      disabled={ disabled }
      renderInput={(params) =>
        <TextField
          { ...params }
          margin={ margin }
          variant={ variant }
          label="Clase"
        />
      }
    />
  );
}
