import OrdersScreen from './screen';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Screen } from '../types';

export default {
  Screen: OrdersScreen,
  Icon: LocalShippingIcon,
  name: 'Pedidos',
  path: '/orders',
  root: true,
} as Screen;
