import gql from 'graphql-tag';
import { useMutation, useQuery } from "@apollo/react-hooks";
import { OrderItemsInput, OrderNotesInput, OrderStatusInput, OrderWithCustomer } from "../types";

const customerOrderItemsFragment = gql`
  fragment CustomerOrderItemsFragment on CustomerOrder {
    items {
      count
      product {
        id
        name
        code
        price
        stock
        uri
      }
    }
  }
`;

const customerOrderFragment = gql`
  fragment CustomerOrderFragment on CustomerOrder {
    customerId
    status
    notes
    createdAt
    code
    customerInfo {
      name
      phoneNumber
      contactMethod
    }
    items {
      count
      product {
        id
        name
        code
        price
        stock
        uri
      }
    }
  }
`;

type FindCustomerOrderByCodeQueryData = { order: OrderWithCustomer };
type FindCustomerOrderByCodeQueryVariables = { code: string | null };
const findCustomerOrderByCodeQuery = gql`
  query findCustomerOrderByCode($code: String!) {
    order: findCustomerOrderByCode(code: $code) {
      ...CustomerOrderFragment
    }
  }
  ${ customerOrderFragment }
`;

type SetOrderStatusMutationVariables = { customerId: string; input: OrderStatusInput };
const setOrderStatusMutation = gql`
  mutation updateOrderStatus($customerId: ID, $input: OrderStatusInput!) {
    order: updateCustomerOrderStatus(customerId: $customerId, input: $input) {
      customerId
      createdAt
      status
    }
  }
`;

type SetOrderNotesMutationVariables = { customerId: string; input: OrderNotesInput };
const setOrderNotesMutation = gql`
  mutation updateOrderNotes($customerId: ID, $input: OrderNotesInput!) {
    order: updateCustomerOrderNotes(customerId: $customerId, input: $input) {
      customerId
      createdAt
      notes
    }
  }
`;

type SetOrderItemsMutationVariables = { customerId: string; input: OrderItemsInput };
const setOrderItemsMutation = gql`
  mutation updateOrderItems($customerId: ID, $input: OrderInput!) {
    order: updateCustomerOrder(customerId: $customerId, input: $input) {
      customerId
      createdAt
      ...CustomerOrderItemsFragment
    }
  }
  ${customerOrderItemsFragment}
`;

export const useFindCustomerOrderByCodeQuery = (variables: FindCustomerOrderByCodeQueryVariables) =>
  useQuery<FindCustomerOrderByCodeQueryData, FindCustomerOrderByCodeQueryVariables>(findCustomerOrderByCodeQuery, { variables, skip: !variables.code });

export const useSetOrderStatusMutation = () => useMutation<{}, SetOrderStatusMutationVariables>(setOrderStatusMutation);
export const useSetOrderNotesMutation = () => useMutation<{}, SetOrderNotesMutationVariables>(setOrderNotesMutation);
export const useSetOrderItemsMutation = () => useMutation<{}, SetOrderItemsMutationVariables>(setOrderItemsMutation);
