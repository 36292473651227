import React from 'react';
import { SearchBarCompareFunction } from "./use-search-bar";
import TableSortLabel from '@material-ui/core/TableSortLabel';

export enum SortOrder {
  desc = 'desc',
  asc = 'asc',
}

type SortingHeaderProps<TSortAttributes> = {
  attribute: TSortAttributes;
  sorting: Sorting<TSortAttributes>;
};

export function SortingHeader<TSortAttributes>({ children, sorting, attribute }: React.PropsWithChildren<SortingHeaderProps<TSortAttributes>>) {
  const toggleSortAttribute = React.useCallback(() => {
    sorting.setSortAttribute(attribute);
  }, [ sorting, attribute ]);

  return (
    <TableSortLabel
      active={ sorting.attribute === attribute }
      direction={ sorting.attribute === attribute ? sorting.order : SortOrder.desc }
      onClick={ sorting.attribute === attribute ? sorting.toggleSortOrder : toggleSortAttribute }
    >
      { children }
    </TableSortLabel>
  );
}

type Sorting<TSortAttributes> = SortCriteria<TSortAttributes> & {
  toggleSortOrder: () => void;
  setSortAttribute: (attribute: TSortAttributes) => void;
}

type SortCriteria<TSortAttributes> = {
  attribute: TSortAttributes;
  order: SortOrder;
};

type SortableItem<TSortAttributes extends string> = {
  [key in TSortAttributes]: SortableValue | undefined | null;
}

type SortableValue = string | number | object[];

export function useSorting<TSortAttributes extends string, TItem extends SortableItem<TSortAttributes>>(initialAttribute: TSortAttributes) {
  const [ sort, setSort ] = React.useState({ attribute: initialAttribute, order: SortOrder.desc } as SortCriteria<TSortAttributes>);

  const sorting: Sorting<TSortAttributes> = React.useMemo(() => {
    const toggleSortOrder = () => {
      setSort({
        attribute: sort.attribute,
        order: sort.order === SortOrder.asc ? SortOrder.desc : SortOrder.asc,
      });
    };

    const setSortAttribute = (attribute: TSortAttributes) => {
      setSort({
        attribute: attribute,
        order: SortOrder.desc,
      });
    };

    return {
      ...sort,
      toggleSortOrder,
      setSortAttribute,
    };
  }, [ setSort, sort ]);

  const sortFn: SearchBarCompareFunction<TItem> = React.useMemo(() => (a: TItem, b: TItem) => {
    const aValue: SortableValue | undefined | null = a[sort.attribute];
    const bValue: SortableValue | undefined | null = b[sort.attribute];
    if (aValue === undefined || aValue === null) {
      if (bValue === undefined || bValue === null) {
        return 0;
      }
      return 1;
    }
    if (bValue === undefined || bValue === null) {
      return -1;
    }

    let value;
    if (typeof aValue === "string" || typeof bValue === "string") {
      if (typeof aValue !== "string" || typeof bValue !== "string") {
        throw new Error('Type mismatch');
      }
      value = aValue.localeCompare(bValue as string);
    } else {
      value = numericValue(aValue) - numericValue(bValue);
    }
    return sort.order === SortOrder.desc ? value : -value;
  }, [ sort ]);

  return [ sorting, sortFn ] as [ Sorting<TSortAttributes>, (a: TItem, b: TItem) => number ];
}

function numericValue(value: number | object[]): number {
  if (Array.isArray(value)) {
    return value.length;
  }
  return value;
}
