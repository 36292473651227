import React from "react";
import { Product } from "../types";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { getStockBehaviour, ProductTag, StockBehaviour } from "@del-alto/shop-util";

const useStyles = makeStyles(() =>
  createStyles({
    behaviourChip: {
      display: 'flex',
      marginTop: 5,
    },
  })
);

const AVAILABLE_STOCK_BEHAVIOR_LABELS = {
  [ProductTag.Requestable]: 'Entrega Inmediata',
  [ProductTag.Arrival]: 'Novedad',
  [ProductTag.Discontinued]: 'Discontinuo',
};

const UNAVAILABLE_STOCK_BEHAVIOR_LABELS = {
  [ProductTag.Requestable]: 'A pedido',
  [ProductTag.Arrival]: 'Próximamente',
  [ProductTag.Discontinued]: 'Discontinuo',
};

const DISABLED_LABEL = 'Inactivo';

function getStockBehaviorLabel(stockUpdate: StockBehaviour, available: boolean, enabled?: boolean): string {
  if (enabled === false) {
    return DISABLED_LABEL;
  }
  return (available ? AVAILABLE_STOCK_BEHAVIOR_LABELS : UNAVAILABLE_STOCK_BEHAVIOR_LABELS)[stockUpdate]
}

export function ProductStock({ product }: { product: Product }) {
  const classes = useStyles();
  const available = product.stock > 0;
  const behaviour = getStockBehaviour(product.tags);
  const enabled = product.enabled;

  return (
    <React.Fragment>
      <span>{ product.stock === null ? '-' : product.stock }</span>
      { enabled === false || behaviour !== 'normal' ? (
        <Chip label={getStockBehaviorLabel(behaviour, available, enabled)} size="small" className={ classes.behaviourChip }/>
      ) : null}
    </React.Fragment>
  );
}
