import { Product } from "../types";

export const deleteProductCopy = (product: Product) => ({
  cancel: 'Cancelar',
  action: 'Borrar',
  title: 'Borrar Producto',
  message: `Esta seguro que desea borrar el producto "${ product.name }"?`,
});

export const deleteProductImageCopy = (product: Product) => ({
  cancel: 'Cancelar',
  action: 'Borrar',
  title: 'Borrar Imagen',
  message: `Esta seguro que desea borrar la imagen del producto "${ product.name }"?`,
});

export const CREATE_PRODUCT_COPY = {
  title: 'Crear Producto',
  action: 'Crear',
  cancel: 'Cancelar',
};

export const EDIT_PRODUCT_COPY = {
  title: 'Editar Producto',
  action: 'Guardar',
  cancel: 'Cancelar',
};

export const BULK_EDIT_PRODUCT_COPY = {
  title: 'Editar Múltiples Productos',
  action: 'Guardar',
  cancel: 'Cancelar',
};

export const UPLOAD_PRODUCT_IMAGE_COPY = {
  action: 'Subir',
};

export const BULK_UPLOAD_PRODUCTS_COPY = {
  action: 'Subir',
};
