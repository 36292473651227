import mime from 'mime-types';
import { getS3 } from "./aws-services";
import { AWS_STATIC_BUCKET_NAME, AWS_STATIC_DISTRIBUTION_URL } from "./constants";

export default async function uploadImage({ key, extension, file }: { key: string; extension: string; file: File }) {
  await getS3().putObject({
    Bucket: AWS_STATIC_BUCKET_NAME,
    Key: `${ key }@orig`,
    Body: file,
    ContentType: mime.contentType(extension) || 'image/jpeg',
  }).promise();

  return `${AWS_STATIC_DISTRIBUTION_URL}${key}`;
}
