import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";

import ProgressButton from "./progress-button";

type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  callback: () => Promise<unknown>;
  copy: {
    title: string;
    message: string;
    action: string;
    cancel: string;
  };
};

export default function ConfirmationDialog({ open, onClose, copy: { title, message, action, cancel }, callback }: ConfirmationDialogProps) {
  const [ active, setActive ] = React.useState(false);

  const onDelete = React.useCallback(async () => {
    setActive(true);
    await callback();
  }, [ setActive, callback ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent>
        <Typography>{ message }</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={ onClose } color="secondary" disabled={active}>
          { cancel }
        </Button>
        <ProgressButton onClick={onDelete} active={active} label={ action }/>
      </DialogActions>
    </Dialog>
  );
}
