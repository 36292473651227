import React, { ChangeEvent, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import { EditFormProps } from "../components/edit-dialog";
import { PlaylistFormModel } from "./types";

type SceneFormProps = EditFormProps<PlaylistFormModel>

export default function PlaylistForm({ model: { label }, setModel, readOnly }: SceneFormProps) {
  const onSetLabel = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setModel({ label: event.target.value });
  }, [ setModel ]);

  return (
    <TextField
      autoFocus
      margin="dense"
      id="label"
      label="Nombre"
      type="text"
      value={label}
      onChange={onSetLabel}
      disabled={readOnly}
      fullWidth
    />
  )
}
