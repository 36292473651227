import React, { ChangeEvent, useCallback } from "react";
import { DocumentNode } from "graphql";
import { OperationVariables } from "@apollo/react-common";
import { useMutation, MutationHookOptions } from "@apollo/react-hooks";

import Button from "@material-ui/core/Button";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from "@material-ui/core/CircularProgress";

import { ButtonProps, mergeButtonProps } from "./button-props";
import uploadImage from "../upload-image";

type UploadButtonProps<TData = any, TVariables = OperationVariables> = {
  inputId: string;
  mutation: DocumentNode;
  buildVariables: (uri: string) => TVariables;
  buildKey: (fileName: string) => string;
  copy: {
    action: string;
  };
  buttonProps?: ButtonProps;
} & Pick<MutationHookOptions<TData, TVariables>, 'update' | 'refetchQueries'>;

export default function UploadButton<TData = any, TVariables = OperationVariables>({ inputId, mutation, buildKey, buildVariables, refetchQueries, update, buttonProps, copy: { action } }: UploadButtonProps<TData, TVariables>) {
  const [ uploading, setUploading ] = React.useState(false);
  const mergedButtonProps = mergeButtonProps(buttonProps);

  const [ updateEntity ] = useMutation<unknown, TVariables>(mutation, {
    refetchQueries,
    update,
    awaitRefetchQueries: true,
  });

  const onUpload = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files!;
    if (!files.length) {
      return;
    }

    const file = files[0];
    const key = buildKey(file.name);
    const extension = file.name.split('.').pop()!;

    setUploading(true);

    const uri = await uploadImage({ key, extension, file });

    await updateEntity({ variables: await buildVariables(uri) });
  }, [ setUploading, buildKey ]);

  return (
    <React.Fragment>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={ inputId }
        type="file"
        onChange={onUpload}
      />
      <label htmlFor={ inputId }>
        <Button
          { ...mergedButtonProps }
          component="span"
          disabled={uploading}
          endIcon={uploading ? <CircularProgress size={20} /> : <CloudUploadIcon /> }
        >
          { action }
        </Button>
      </label>
    </React.Fragment>
  );
}
