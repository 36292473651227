import { Dimensions, StockBehaviour, StockBehaviourTag } from "./types";
import { ProductTag } from "@del-alto/data-types";

const THOUSANDS_REGEXP = /(\d)(?=(\d{3})+(?!\d))/g;
export const STOCK_BEHAVIOUR_TAGS: StockBehaviourTag[] = [ ProductTag.Discontinued, ProductTag.Arrival, ProductTag.Requestable ];

const HOT_SALE_GROUP_DISCOUNT_FROM = 5000;
export const GROUP_DISCOUNT_FROM = 1000000000;
export const GROUP_DISCOUNT = 0;

export function formatPrice(price: number) {
  const formattedPrice = price
    .toFixed(0)
    .replace(THOUSANDS_REGEXP, '$1.');

  return `$${ formattedPrice }`;
}

export function getIndividualDiscount(price: number, tags: ProductTag[] | null, available: boolean) {
  if (!available) {
    return 0;
  }

  if (isOnHotSale(tags)) {
    return 0.3;
  }

  if (isOnSale(tags)) {
    return 0.2;
  }

  return getGroupDiscount(price, available);
}

export function getGroupDiscount(totalPrice: number, available: boolean) {
  if (!available) {
    return 0;
  }

  const hotSale = isHotSale();

  if (hotSale && totalPrice > HOT_SALE_GROUP_DISCOUNT_FROM) {
    return 0.2;
  }

  if (totalPrice > GROUP_DISCOUNT_FROM) {
    return GROUP_DISCOUNT;
  }

  return 0;
}

export function showDiscountPrice(discount: number) {
  return discount >= 0.2;
}

export function showDiscountTag(price: number, tags: ProductTag[] | null, available: boolean) {
  const hotSale = isHotSale();
  const discount = getIndividualDiscount(price, tags, available);
  return hotSale && discount && (discount >= 0.3 || price > 10000);
}

export function getFinalDiscount(individualPrice: number, totalPrice: number, tags: ProductTag[] | null, available: boolean) {
  return Math.max(getIndividualDiscount(individualPrice, tags, available), getGroupDiscount(totalPrice, available));
}

export function getFinalPrice(individualPrice: number, totalPrice: number, tags: ProductTag[] | null, available: boolean) {
  const discount = getFinalDiscount(individualPrice, totalPrice, tags, available);
  return individualPrice * (1 - discount);
}

export function getStockBehaviour(tags: ProductTag[] | null): StockBehaviour {
  const tagPool = tags || [];
  return STOCK_BEHAVIOUR_TAGS.reduce(
    (behaviour, tag) => behaviour || (tagPool.includes(tag) ? tag : null), null
  ) || 'normal';
}

export function isOnSale(tags: ProductTag[] | null) {
  const hotSale = isHotSale();
  const tagPool = tags || [];
  return !hotSale && tagPool.includes(ProductTag.Sale);
}

export function isOnHotSale(tags: ProductTag[] | null) {
  const hotSale = isHotSale();
  const tagPool = tags || [];
  return hotSale && (tagPool.includes(ProductTag.HotSale) || tagPool.includes(ProductTag.Sale));
}

export function isHotSale() {
  const time = Date.now();
  return 1595786400000 < time && time <  1596078000000;
}

const S3_STATIC_ORIGIN = /^https:\/\/delalto-static.s3-sa-east-1.amazonaws.com\//;
const CLOUDFRONT_STATIC_ORIGIN = `https://static.delaltodigital.com/`;
const getPlaceholderUri = (text: string) => `https://via.placeholder.com/800x600?text=${text}`;

export enum ImageVariant {
  Original = 'orig',
  Thumbnail = 'thumb',
  Related = 'related',
  Full = 'full',
  Half = 'half',
}

export function getCdnUri(uri: string, variant: ImageVariant = ImageVariant.Original) {
  return uri && `${uri.replace(S3_STATIC_ORIGIN, CLOUDFRONT_STATIC_ORIGIN)}@${variant}`;
}

export function getPlaceholder(type?: 'product' | 'scene') {
  return getPlaceholderUri(type ? (type === 'product' ? 'PRODUCTO' : 'ESCENA') : '?');
}

export function generateShopProductLink(id: string) {
  return `https://www.delaltodigital.com/product/${id}`;
}

export function measureImage(uri: string): Promise<Dimensions> {
  return new Promise(resolve => {
    const img = new Image();

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.src = uri;
  });
}
