import React, { ChangeEvent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Order } from "../types";
import { ORDER_STATUS_LABELS } from "./copy";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useToggle } from "@del-alto/shop-util";
import ProgressButton from "../components/progress-button";
import { Grow } from "../components/grow";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      margin: '0 24px 24px 0',
    },
    title: {
      fontSize: 14,
    },
    notesFields: {
      width: '100%',
      marginTop: 20,
    },
  })
);

type OrderCardProps = {
  order: Order;
  onSaveNotes: (notes: string) => Promise<void>;
  actions: React.ReactNode;
};

export default function OrderCard({ order, onSaveNotes, actions }: OrderCardProps) {
  const [ editingNotes, startEditingNotes, onStopEditingNotes ] = useToggle();
  const [ savingNotes, startSavingNotes, stopSavingNotes ] = useToggle();
  const [ notesDraft, setNotesDraft ] = React.useState('');
  const classes = useStyles();
  const {
    items,
    status,
    createdAt,
    notes,
  } = order;

  const onSetNotesDraft = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNotesDraft(event.target.value);
    startEditingNotes();
  }, [ startEditingNotes, setNotesDraft ]);

  const onSave = React.useCallback(async () => {
    startSavingNotes();
    try {
      await onSaveNotes(notesDraft);
      onStopEditingNotes();
    } catch(e) { /*ignore */ }
    stopSavingNotes();
  }, [ notesDraft, onSaveNotes, onStopEditingNotes, stopSavingNotes, startSavingNotes ]);

  const count = items.reduce((memo, item) => memo + item.count, 0);
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Pedido
        </Typography>
        <Typography variant="body2" component="p">
          Fecha: { createdAt }
          <br/>
          Estado: { ORDER_STATUS_LABELS[status] }
          <br/>
          Cantidad de Items: { count }
        </Typography>
        <TextField
          className={ classes.notesFields }
          id="outlined-multiline-static"
          label="Notas"
          multiline
          variant="outlined"
          onChange={ onSetNotesDraft }
          value={ editingNotes ? notesDraft : (notes || '') }
        />
      </CardContent>
      <CardActions>
        { editingNotes ? (
          <React.Fragment>
            <Button
              size="small"
              color="secondary"
              onClick={ onStopEditingNotes }
              disabled={ savingNotes }
            >
              Cancelar
            </Button>
            <Grow />
            <ProgressButton
              onClick={ onSave }
              active={ savingNotes }
              label="Guardar"
            />
          </React.Fragment>
        ) : actions }
      </CardActions>
    </Card>
  );
}
