import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export function Grow() {
  const classes = useStyles();

  return (
    <div className={classes.root} />
  )
}
