import HomeIcon from '@material-ui/icons/Home';
import { Screen } from '../types'
import HomeScreen from './screen'

export default {
  Screen: HomeScreen,
  Icon: HomeIcon,
  name: 'Inicio',
  path: '/',
  root: true,
} as Screen;
