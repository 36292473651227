import IntegrationsScreen from './screen';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import { Screen } from '../types';

export default {
  Screen: IntegrationsScreen,
  Icon:   SettingsInputComponentIcon,
  name: 'Integraciones',
  path: '/integrations',
  root: true,
} as Screen;
