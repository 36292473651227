import gql from 'graphql-tag';
import { useMutation } from "@apollo/react-hooks";

export const updateSearchIndexMutation = gql`
  mutation updateSearchIndex {
    updateSearchIndex
  }
`;

export const refreshPricesAndStockMutation = gql`
  mutation updateSearchIndex {
    refreshPricesAndStock
  }
`;

export const updateBackendIPMutation = gql`
  mutation updateBackendIP {
    updateBackendIP
  }
`;

export const useUpdateSearchIndexMutation = () => useMutation<boolean, {}>(updateSearchIndexMutation);
export const useRefreshPricesAndStockMutation = () => useMutation<boolean, {}>(refreshPricesAndStockMutation);
export const useUpdateBackendIPMutation = () => useMutation<boolean, {}>(updateBackendIPMutation);
