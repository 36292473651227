import React from "react";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useToggle } from "@del-alto/shop-util";
import { useMutation, MutationHookOptions } from "@apollo/react-hooks";
import ConfirmationDialog from "./confirmation-dialog";
import { OperationVariables } from "@apollo/react-common";
import { DocumentNode } from "graphql";
import ProgressButton from "./progress-button";
import useAlert from "../util/use-alert";

type DeleteIconButtonProps<TData = any, TVariables = OperationVariables> = DeleteWithoutConfirmationIconButtonProps<TData, TVariables> & {
  copy: {
    title: string;
    message: string;
    action: string;
    cancel: string;
  };
};

type DeleteWithoutConfirmationIconButtonProps<TData = any, TVariables = OperationVariables> = {
  mutation: DocumentNode;
  icon?: React.ReactNode;
} & Pick<MutationHookOptions<TData, TVariables>, 'variables' | 'update' | 'refetchQueries'>;

export default function DeleteIconButton<TData = any, TVariables = OperationVariables>({ mutation, variables, refetchQueries, update, copy, icon }: DeleteIconButtonProps<TData, TVariables>) {
  const [  confirmationOpen,  onOpenConfirmation ,  onCloseConfirmation ] = useToggle();

  const [ runAction ] = useMutation(mutation, {
    variables,
    refetchQueries,
    update,
    awaitRefetchQueries: true,
  });

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={ confirmationOpen }
        onClose={ onCloseConfirmation }
        callback={ runAction }
        copy={ copy }
      />
      <IconButton onClick={ onOpenConfirmation }>
        { icon || <DeleteIcon /> }
      </IconButton>
    </React.Fragment>
  );
}

export function DeleteWithoutConfirmationIconButton<TData = any, TVariables = OperationVariables>({ mutation, variables, refetchQueries, update, icon }: DeleteWithoutConfirmationIconButtonProps<TData, TVariables>) {
  const [ runAction, { loading } ] = useMutation(mutation, {
    refetchQueries,
    update,
    awaitRefetchQueries: true,
  });

  const [ alert, showAlert ] = useAlert();

  const onDelete = React.useCallback(async () => {
    try {
      const resolvedVariables = typeof variables === 'function' ? variables() : variables;
      await runAction({
        variables: resolvedVariables,
      });
    } catch(e) {
      showAlert({ message: e.message || e, severity: 'error' });
    }
  }, [ runAction, showAlert ]);

  return (
    <React.Fragment>
      <ProgressButton
        iconButton
        active={ loading }
        icon={ icon || <DeleteIcon/> }
        label="Eliminar"
        onClick={ onDelete }
      />
      { alert }
    </React.Fragment>
  );
}
