import React, { useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';

type AlertState = OpenAlertState | CloseAlertState;

type OpenAlertState = {
  open: true;
  message: string;
  severity: Color;
}

type CloseAlertState = {
  open: false;
}

export type ShowAlertProps = {
  message: unknown;
  severity: Color;
}

const CLOSE_STATE: AlertState = { open: false };

export default function useAlert(): [ React.ReactNode, (props: ShowAlertProps) => void ] {
  const [ alertState, setAlertState ] = React.useState(CLOSE_STATE);

  const showAlert = useCallback(({ message, severity }: ShowAlertProps) => {
    setAlertState({
      open: true,
      message: String(message),
      severity,
    });
  }, [ setAlertState ]);

  const closeAlert = useCallback(() => {
    setAlertState(CLOSE_STATE)
  }, [ setAlertState ]);

  const alert = (
    <Snackbar open={ alertState.open } autoHideDuration={ 6000 } onClose={ closeAlert }>
      { alertState.open ? (<Alert onClose={ closeAlert } severity={ alertState.severity } elevation={6} variant="filled">
        { alertState.message }
      </Alert>) : null}
    </Snackbar>
  );

  return [ alert, showAlert ];
}
