import React from 'react';
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useListProductsQuery } from "./queries";
import { Product } from "../types";

export type ProductAttributeSetSelectorProps = {
  label?: string;
  value: string[];
  onChange: (value: string[]) => void;
  availableProducts?: Product[];
  disabled: boolean;
  variant?: TextFieldProps["variant"];
  margin?: TextFieldProps["margin"];
  propertyExtractor: (products: Product[] | null) => string[];
  allowCreation?: boolean;
}

const NEW_ENTRY_REGEXP = /^Nuevo: "(.*)"$/;

const filter = createFilterOptions<string>({
  trim: true,
  ignoreAccents: true,
  ignoreCase: true,
});

export default function ProductAttributeSetSelector({ value, onChange, disabled, availableProducts, propertyExtractor, allowCreation, variant = "standard", margin = "normal", label = "Seleccionar" }: ProductAttributeSetSelectorProps) {

  const { loading, error, data } = useListProductsQuery();

  const allProducts = availableProducts ? availableProducts : ((loading || error || !data) ? null : data.products.items);

  const allValues = propertyExtractor(allProducts);

  const filterOptions = React.useCallback((options, params) => {
    const filtered = filter(options, params);

    if (allowCreation !== false && params.inputValue !== '' && !allValues.includes(params.inputValue)) {
      filtered.push(`Nuevo: "${params.inputValue}"`);
    }

    return filtered;
  }, []);

  const onSelectOptions = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string[]) => {
    const values = value.map(singleValue => {
      const newMatch = singleValue && singleValue.match(NEW_ENTRY_REGEXP);
      return newMatch ? newMatch[1] : singleValue;
    });
    onChange(values);
  },[ onChange ]);

  return (
    <Autocomplete
      selectOnFocus
      options={ allValues }
      style={{ flex: 1 }}
      filterOptions={ filterOptions }
      onChange={ onSelectOptions }
      value={ value }
      multiple={ true }
      freeSolo={ true }
      disableClearable={ true }
      disabled={ disabled }
      renderInput={(params) =>
        <TextField
          { ...params }
          margin={ margin }
          variant={ variant }
          label={ label }
        />
      }
    />
  );
}
