import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React, { ChangeEvent } from "react";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      minWidth: 350,
    },
  })
);

type SearchBoxProps<T> = {
  stringify?: (option: T) => string;
  value: T | null;
  onSelect: (value: T) => void;
  disabled: boolean;
  label: string;
  autoFocus: boolean;
  options: T[];
  renderOption?: (option: T) => React.ReactNode;
  getOptionLabel?: (option: T) => string;
};

export default function SearchBox<T>({ value, onSelect, label, disabled, autoFocus, options, stringify, getOptionLabel, renderOption }: SearchBoxProps<T>) {
  const filterOptions = React.useMemo(() => createFilterOptions({
    stringify,
  }), [ stringify ]);


  const classes = useStyles();

  const onChange = React.useCallback((event: ChangeEvent<HTMLInputElement>, value: T) => {
    onSelect(value);
  }, [ onSelect ]);

  return (
    <Autocomplete
      className={classes.root}
      blurOnSelect
      options={ options }
      onChange={ onChange }
      value={ value }
      multiple={false}
      filterOptions={filterOptions}
      disabled={disabled}
      renderInput={(params) =>
        <TextField
          { ...params }
          autoFocus={autoFocus}
          fullWidth
          label={ label }
          variant="outlined"
        />
      }
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
    />
  );
}

const LISTBOX_PADDING = 8;
const LISTBOX_ITEM_SIZE = 48;

const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;

  const height = Math.min(8, itemCount) * LISTBOX_ITEM_SIZE + 2 * LISTBOX_PADDING;

  console.log('other', other);
  return (
    <div ref={ref} {...other}>
      <FixedSizeList
        itemData={itemData}
        height={height}
        width="100%"
        itemSize={ LISTBOX_ITEM_SIZE }
        itemCount={ itemCount }
      >
        { renderRow }
      </FixedSizeList>
    </div>
  );
});

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}
