import { Product, ProductSummary } from "../types";
import getApolloClient from "../apollo-client";
import {
  findProductByCodeQuery,
  FindProductByCodeQueryData,
  FindProductByCodeQueryVariables, listProductsQuery,
  ListProductsQueryData
} from "./queries";

export async function getProductByCode(code: string): Promise<ProductSummary> {
  const product = await findProductByCode(code);

  if (!product) {
    throw new Error(`Product with code ${ code } not found.`)
  }

  return product;
}

export async function findProductByCode(code: string): Promise<ProductSummary | null> {
  const apolloClient = await getApolloClient();
  const { data } = await apolloClient.query<FindProductByCodeQueryData, FindProductByCodeQueryVariables>({
    query: findProductByCodeQuery,
    variables: { code },
  });

  return data.products[0] as ProductSummary | null;
}

export async function getAllProducts(): Promise<Product[]> {
  const apolloClient = await getApolloClient();
  const { data } = await apolloClient.query<ListProductsQueryData>({ query: listProductsQuery });

  return data.products.items;
}
