export type ButtonProps = {
  size?: 'small' | 'medium';
  color?: 'primary' | 'secondary' | 'default';
  variant?: 'contained' | 'outlined' | 'text';
}

const DEFAULT_BUTTON_PROPS: ButtonProps = {
  size: 'medium',
  variant: 'contained',
  color: 'primary',
};

export function mergeButtonProps(buttonProps?: ButtonProps) {
  return {
    ...DEFAULT_BUTTON_PROPS,
    ...(buttonProps || {})
  };
}
