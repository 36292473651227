import React from "react";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { drawerWidth } from "./drawer";
import { Grow } from "./grow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: `calc(100% - ${ drawerWidth }px)`,
    },
    content: {
      padding: theme.spacing(3),
    },
    buttonBar: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonWrapper: {
      marginLeft: 10,
      display: 'inline-block'
    }
  }),
);

type ContentFrameProps = {
  title: string;
  children: React.ReactNode;
  actions?: React.ReactNode[] | null;
}

export default function ContentFrame({ title, children, actions }: ContentFrameProps) {
  const classes = useStyles();

  return (
    <div>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <Typography variant="h6" noWrap>{ title }</Typography>
          <Grow/>
          { actions ? (
            <div className={ classes.buttonBar }>
              { actions.map((button, i) => (
                <div className={ classes.buttonWrapper} key={i}>
                  { button }
                </div>
              ))}
            </div>
          ) : null }
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div className={classes.content}>
        { children }
      </div>
    </div>
  );
}
