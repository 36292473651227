import debounce from 'lodash/fp/debounce';
import React  from "react";
import ContentFrame from "../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  useGetLinkQuery
} from "./queries";
import TextField from "@material-ui/core/TextField";
import ButtonsBar from "../components/buttons-bar";
import { Link } from "./types";

export default function LinksScreen() {
  const [ laid, setLaid ] = React.useState('');
  const [ search, setSearch ] = React.useState('');
  const debouncedSetSearch = React.useMemo(
    () => debounce(500, setSearch),
    [ setSearch ]
  );

  React.useEffect(() => {
    debouncedSetSearch(laid);
  }, [ debouncedSetSearch, laid ]);

  const { loading, error, data } = useGetLinkQuery(search);

  const onSetLaid = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLaid(e.target.value);
  }, [ setLaid ]);

  const links: null | Link[] = (loading || error || !data) ? null : data.links;

  // @ts-ignore
  window.allLinks = links;

  let content: React.ReactElement;

  if (loading) {
    content = (<CircularProgress />);
  } else if (error) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    const totalViews = (links || []).reduce((count, link) => count + link.views, 0);
    content = (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Usuario
              </TableCell>
              <TableCell>
                Visitas ({ totalViews })
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { (links || []).map((link: Link) => (
              <TableRow key={ link.customerId }>
                <TableCell>
                  { link.customerId }
                </TableCell>
                <TableCell>
                  { link.views }
                </TableCell>
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <ContentFrame title="Links">
      <div>
        <ButtonsBar>
          <TextField
            label="Search"
            variant="outlined"
            value={ laid }
            onChange={ onSetLaid }
          />
        </ButtonsBar>
        { content }
      </div>
    </ContentFrame>
  );
}
