import * as React from 'react';

import {
  Switch,
  Route,
} from "react-router-dom";
import { Router } from "react-router";

import history from './history';

import { NavBar } from "./navigation";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "./components/drawer";
import screens from './screens';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      marginLeft: drawerWidth,
    },
  }),
);

const screensHomeLast = [ ...screens ].reverse();

export default function Routes() {
  const classes = useStyles();

  return (
    <Router history={history}>
      <NavBar />
      <div>
        <main className={classes.content}>
          <Switch>
            { screensHomeLast.map(({ path, Screen }) => (
              <Route path={ path } key={ path } exact={true}>
                <Screen />
              </Route>
            ))}
          </Switch>
        </main>
      </div>
    </Router>
  );
}
