import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import { Checkbox, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(() =>
  createStyles({
    selectionCell: {
      width: 80,
    },
  }),
);

export default function useTableSelection<T extends { id: string }>(items: null | T[]): [ (item: T) => React.ReactNode, React.ReactNode, T[], React.ReactNode ] {
  const classes = useStyles();
  const [ selection, setSelection ] = React.useState([] as T[]);
  const [ lastSelected, setLastSelected ] = React.useState(null as T | null);

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setLastSelected(null);
    setSelection((checked && items) || []);
  };

  const onSelect = (event: React.MouseEvent<HTMLButtonElement>, selected: T) => {
    const { shiftKey, target } = event;
    const checked = (target as HTMLInputElement).checked;
    let result;
    if (items && shiftKey && checked && lastSelected) {
      // select intermediate elements
      const startIndex = items.indexOf(lastSelected);
      const endIndex = items.indexOf(selected);
      if (startIndex !== -1 && endIndex !== -1) {
        const firstIndex = Math.min(startIndex, endIndex);
        const lastIndex = Math.max(startIndex, endIndex);
        result = selection.filter(item => {
          const itemIndex = items.indexOf(item);
          return firstIndex > itemIndex || itemIndex > lastIndex;
        });
        // change handler takes care of selecting the last element
        result = result.concat(items.slice(firstIndex, lastIndex + 1));
      }
    }
    if (!result) {
      result = selection.filter(item => selected.id !== item.id);
      if (checked) {
        result.push(selected);
        setLastSelected(selected);
      } else {
        setLastSelected(null);
      }
    }
    setSelection(result);
  };

  const rowCount = (items || []).length;
  const numSelected = selection.length;

  const toggleSelection = (item: T) => (
    <TableCell padding="checkbox" className={ classes.selectionCell }>
      <Checkbox
        checked={ selection.includes(item) }
        onClick={ (event) => onSelect(event, item) }
        inputProps={{ 'aria-label': 'select all desserts' }}
      />
    </TableCell>
  );

  const selectionCountCell = (
    <TableCell className={ classes.selectionCell }>
      <Chip label={ selection.length } />
    </TableCell>
  );

  const selectAllCell = (
    <TableCell padding="checkbox" className={ classes.selectionCell }>
      <Checkbox
        indeterminate={ numSelected > 0 && numSelected < rowCount }
        checked={ rowCount > 0 && numSelected === rowCount }
        onChange={ onSelectAllClick }
        inputProps={{ 'aria-label': 'select all desserts' }}
      />
    </TableCell>
  );

  return [
    toggleSelection,
    selectAllCell,
    selection,
    selectionCountCell,
  ];
}
