import React, { ChangeEvent, useCallback } from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export type FilterDropdownFilterFunction<T> = (item: T, selectedOption: string) => boolean;
export default function useFilterDropdown<T>(items: null | T[], options: string[], filter: FilterDropdownFilterFunction<T>, label: string): [ React.ReactNode, null | T[] ] {
  const [ selectedOption, setSelectedOption ] = React.useState('');

  const onSetSelection = useCallback((event: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedOption(value);
  },[ setSelectedOption ]);

  const filteredItems = React.useMemo(() => {
    if (!items) {
      return null;
    }
    if (!selectedOption) {
      return items;
    }

    return items.filter(item => filter(item, selectedOption));
  },[ items, filter, selectedOption ]);

  const searchBar = (
    <Autocomplete
      options={ options }
      style={{ width: 300 }}
      onChange={ onSetSelection }
      value={ selectedOption }
      multiple={false}
      renderInput={(params) =>
        <TextField
          { ...params }
          label={ label }
          variant="outlined"
        />
      }
    />
  );

  return [ searchBar, filteredItems ];
}
