import ScenesScreen from './screen';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { Screen } from '../types';

export default {
  Screen: ScenesScreen,
  Icon: PhotoLibraryIcon,
  name: 'Escenas',
  path: '/scenes',
  root: true,
} as Screen;

export { default as tags } from './tags'
