import React from 'react';
import { useAllKeywords } from "./use-product-properties";
import ProductAttributeSetSelector, { ProductAttributeSetSelectorProps } from "./product-attribute-set-selector";

type ProductKeywordsSelectorProps = Omit<ProductAttributeSetSelectorProps, 'propertyExtractor'>;

export default function ProductKeywordsSelector(props: ProductKeywordsSelectorProps) {
  return <ProductAttributeSetSelector
    {...props}
    label={ props.label || 'Palabras Clave' }
    propertyExtractor={ useAllKeywords }
  />;
}
