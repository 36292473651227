import CustomerScreen from './screen';
import ContactsIcon from '@material-ui/icons/Contacts';
import { Screen } from '../types';

export default {
  Screen: CustomerScreen,
  Icon: ContactsIcon,
  name: 'Cliente',
  path: '/customers/:customerId',
  root: false,
} as Screen;
