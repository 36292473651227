import React from "react";
import shortid from "shortid";
import keyBy from 'lodash/fp/keyBy';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SyncIcon from '@material-ui/icons/Sync';

import ContentFrame from "../components/content-frame";
import ButtonsBar from "../components/buttons-bar";
import {
  bulkUpdateProductMutation,
  createProductMutation,
  deleteProductMutation,
  getProductsInfo,
  listProductsQuery,
  updateProductMutation,
  UpdateProductMutationVariables,
  useListProductsQuery,
  useUpdateProductMutation
} from "./queries";
import FormButton from "../components/form-button";
import {
  BulkEditProductFormModel,
  CreateProductFormModel,
  EditProductFormModel,
  getBulkAvailability,
  toBulkEditModel,
  toFormModel,
  toPersistentModel
} from "./form-model";
import CreateProductForm from "./create-form";
import {
  BULK_EDIT_PRODUCT_COPY,
  BULK_UPLOAD_PRODUCTS_COPY,
  CREATE_PRODUCT_COPY,
  deleteProductCopy,
  deleteProductImageCopy,
  EDIT_PRODUCT_COPY,
  UPLOAD_PRODUCT_IMAGE_COPY
} from "./copy";
import UploadButton from "../components/upload-button";
import DeleteIconButton from "../components/delete-icon-button";
import BackspaceIcon from '@material-ui/icons/Backspace';
import BulkUploadButton from "../components/bulk-upload-button";
import getApolloClient from "../apollo-client";
import EditProductForm from "./edit-form";
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import { Product, UpdateProductInput } from "../types";
import { findProductByCode } from "./service";
import ImagePreview from "../components/image-preview";
import useSearchBar, { SearchBarCompareFunction, SearchBarFilterFunction } from "../util/use-search-bar";
import useFilterDropdown, { FilterDropdownFilterFunction } from "../util/use-filter-dropdown";
import { ProductPrice } from "./product-price";
import { ProductStock } from "./product-stock";
import ProgressButton from "../components/progress-button";
import { generateShopProductLink, useToggle } from "@del-alto/shop-util";
import useAlert from "../util/use-alert";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import useTableSelection from "../util/use-table-selection";
import Chip from "@material-ui/core/Chip";
import BulkEditProductForm from "./bulk-edit-form";
import useInfiniteScrolling from "../util/use-infinite-scrolling";
import CountCell from "../components/count-cell";
import Link from "@material-ui/core/Link";
import { useAllCategories, useAllClasses, useAllProviders } from "./use-product-properties";
import { EditFormProps } from "../components/edit-dialog";
import { SortingHeader, SortOrder, useSorting } from "../util/use-sorting";
import { useAddItemUpdate, useRemoveItemUpdate } from "../util/use-full-scan-query";

const NO_CLASS = 'Sin clase';
const NO_CATEGORY = 'Sin categoría';

const filterProductByProvider: FilterDropdownFilterFunction<Product> = (product: Product, selectedOption: string) => {
  return product.code.split('-')[0] === selectedOption;
};

const filterProductByClass: FilterDropdownFilterFunction<Product> = (product: Product, selectedOption: string) => {
  return product.class === (selectedOption === NO_CLASS ? null : selectedOption);
};

const filterProductByCategory: FilterDropdownFilterFunction<Product> = (product: Product, selectedOption: string) => {
  return selectedOption === NO_CATEGORY ?
    !(product.categories || []).length :
    (product.categories || []).includes(selectedOption);
};

const searchProduct: SearchBarFilterFunction<Product> = (product: Product, searchTerm: string) => {
  if (searchTerm.indexOf('-') !== -1) {
    return product.code.toLocaleLowerCase().startsWith(searchTerm);
  }
  return [ product.code, product.name, product.description ].join(' ').toLocaleLowerCase().indexOf(searchTerm) !== -1;
};

const compareProduct: SearchBarCompareFunction<Product> = (a: Product, b: Product) => {
  const [ lettersA, numberA ] = a.code.split('-');
  const [ lettersB, numberB ] = b.code.split('-');

  return lettersB.localeCompare(lettersA) || (parseInt(numberB) - parseInt(numberA));
};

const orderedCompareProduct = {
  [SortOrder.asc]: compareProduct,
  [SortOrder.desc]: (a: Product, b: Product) => -compareProduct(a, b),
};

const useStyles = makeStyles(() =>
  createStyles({
    productPriceCell: {
      textAlign: 'right',
    },
    selectionRow: {
      height: 81,
    }
  })
);

type SortAttributes = 'code' | 'name' | 'stock';

export default function ProductsScreen() {
  const classes = useStyles();

  const { ready, loading, error, data } = useListProductsQuery();
  const [ sorting, sortFn ] = useSorting<SortAttributes, Product>('code');

  const allProducts = (loading || error || !data || !ready) ? null : data.products.items;
  // @ts-ignore
  window.allProducts = allProducts;

  const allProviders = useAllProviders(allProducts);
  const allClasses = useAllClasses(allProducts);
  const allCategories = useAllCategories(allProducts);
  if (!allClasses.includes(NO_CLASS)) {
    allClasses.unshift(NO_CLASS);
  }
  if (!allCategories.includes(NO_CATEGORY)) {
    allCategories.unshift(NO_CATEGORY);
  }

  const [ providerFilter, providerFilteredProducts ] = useFilterDropdown(
    allProducts,
    allProviders,
    filterProductByProvider,
    'Proveedor',
  );

  const [ classFilter , classFilteredProducts ] = useFilterDropdown(
    providerFilteredProducts,
    allClasses,
    filterProductByClass,
    'Clase',
  );

  const [ categoryFilter , filteredProducts ] = useFilterDropdown(
    classFilteredProducts,
    allCategories,
    filterProductByCategory,
    'Categoría',
  );

  const [ searchBar, searchedProducts ] = useSearchBar(
    filteredProducts,
    searchProduct,
    sorting.attribute === 'code' ? orderedCompareProduct[sorting.order] : sortFn,
  );

  const [ toggleSelectionCell, selectAllCell, selection ] = useTableSelection(searchedProducts);

  const [ sentinel, products, buffered ] = useInfiniteScrolling(searchedProducts, 'td');

  let actions: React.ReactElement[] | null = null;
  let content: React.ReactElement;

  if (loading || !ready) {
    content = (<CircularProgress />);
  } else if (error) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    actions = [
      <CreateProductButton key='create' />,
      <BulkUploadProductsButton key='upload' />,
    ];

    content = (
      <div>
        <ButtonsBar>
          { providerFilter }
          { classFilter }
          { categoryFilter }
          { searchBar }
        </ButtonsBar>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                { selectAllCell }
                <TableCell>
                  <SortingHeader attribute='code' sorting={ sorting }>
                    Código
                  </SortingHeader>
                </TableCell>
                <TableCell>
                  <SortingHeader attribute='name' sorting={ sorting }>
                    Nombre
                  </SortingHeader>
                </TableCell>
                <TableCell>
                  <SortingHeader attribute='stock' sorting={ sorting }>
                    Stock
                  </SortingHeader>
                </TableCell>
                <TableCell className={classes.productPriceCell}>Precio</TableCell>
                <TableCell>Imagen</TableCell>
                <CountCell items={searchedProducts} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={selection.length ? 3 : 7 } className={ classes.selectionRow }><Chip label={ selection.length } /></TableCell>
                { selection.length ? (
                  <React.Fragment>
                    <TableCell>{ selection.reduce((count, item) => count + item.stock, 0) }</TableCell>
                    <TableCell colSpan={2} />
                    <TableCell>
                      <BulkEditProductButton products={ selection } />
                      <BulkSyncProductButton products={ selection } />
                    </TableCell>
                  </React.Fragment>
                ): null}
              </TableRow>
              { (products || []).map((product: Product) => (
                <TableRow key={ product.id }>
                  { toggleSelectionCell(product) }
                  <TableCell>{ product.code }</TableCell>
                  <TableCell>
                    <Link href={ generateShopProductLink(product.id) } target="_blank">
                      { product.name }
                    </Link>
                  </TableCell>
                  <TableCell><ProductStock product={product} /></TableCell>
                  <TableCell className={classes.productPriceCell}><ProductPrice product={product} /></TableCell>
                  <TableCell>{ product.uri ? (
                    <React.Fragment>
                      <ImagePreview uri={ product.uri }/>
                      <DeleteProductImageButton product={ product } />
                    </React.Fragment>
                  ) : (
                    <UploadProductImageButton product={ product }/>
                  ) }</TableCell>
                  <TableCell>
                    <EditProductButton product={ product } />
                    <SyncProductButton product={ product } />
                    <DeleteProductButton product={ product } />
                  </TableCell>
                </TableRow>
              )) }
              <tr style={{ height: 81 * buffered }}>{ sentinel }</tr>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame
      title="Productos"
      actions={ actions }
    >
      { content }
    </ContentFrame>
  );
}

function DeleteProductButton({ product }: { product: Product }) {
  const variables = React.useMemo(
    () => ({ product: { id: product.id } }),
    [ product ]
  );

  const update = useRemoveItemUpdate('products', listProductsQuery, product);

  const copy = React.useMemo(
    () => deleteProductCopy(product),
    [ product ]
  );

  return (
    <DeleteIconButton
      mutation={ deleteProductMutation }
      variables={ variables }
      update={ update }
      copy={ copy }
    />
  );
}

function DeleteProductImageButton({ product }: { product: Product }) {
  const variables = React.useMemo(
    () => ({ product: { id: product.id, uri: null } }),
    [ product ]
  );

  const copy = React.useMemo(
    () => deleteProductImageCopy(product),
    [ product ]
  );

  return (
    <DeleteIconButton
      mutation={ updateProductMutation }
      variables={ variables }
      copy={ copy }
      icon={ <BackspaceIcon /> }
    />
  );
}

export function SyncProductButton({ product }: { product: Product }) {
  const [ updateProduct, { loading } ] = useUpdateProductMutation();
  const [ fetching, startFetching, stopFetching ] = useToggle();
  const [ alert, showAlert ] = useAlert();

  const onRefresh = React.useCallback(async () => {
    startFetching();
    try {
      const update = await buildProductFromCode(product.code);
      // noinspection JSIgnoredPromiseFromCall
      await updateProduct({
        variables: {
          product: {
            id: product.id,
            price: update.price,
            stock: update.stock,
          },
        },
      });
    } catch(e) {
      showAlert({ message: e.message || e, severity: 'error' });
    }
    stopFetching();
  }, [ product.code, showAlert, stopFetching, startFetching ]);

  return (
    <React.Fragment>
      <ProgressButton
        iconButton
        active={ loading || fetching }
        icon={ <SyncIcon/> }
        label="Sincronizar"
        onClick={ onRefresh }
      />
      { alert }
    </React.Fragment>
  );
}

export function BulkEditProductButton({ products }: { products: Product[] }) {
  return (
    <FormButton<BulkEditProductFormModel>
      Form={({ model, setModel, readOnly }: EditFormProps<BulkEditProductFormModel>) =>
        <BulkEditProductForm model={model} setModel={setModel} readOnly={readOnly} products={ products }/>
      }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({
          class: '',
          sale: null,
          hotSale: null,
          stockBehaviour: null,
          enabled: null,
          addKeywords: [],
          removeKeywords: [],
          addCategories: [],
          removeCategories: [],
          available: getBulkAvailability(products),
        } as BulkEditProductFormModel);
        return { model, setModel, isValid: true };
      }}
      mutation={ bulkUpdateProductMutation }
      buildVariables={ async formValues => ({
        input: toBulkEditModel(products.map(product => product.id), formValues),
      })}
      copy={ BULK_EDIT_PRODUCT_COPY }
      iconButton={ true }
      icon={ <CreateIcon fontSize="inherit" /> }
    />
  )
}

export function BulkSyncProductButton({ products }: { products: Product[] }) {
  const [ updateProduct, { loading } ] = useUpdateProductMutation();
  const [ fetching, startFetching, stopFetching ] = useToggle();
  const [ alert, showAlert ] = useAlert();

  const onRefresh = React.useCallback(async () => {
    startFetching();
    try {
      const updates = await getProductUpdates(products);
      // noinspection JSIgnoredPromiseFromCall
      await updates.reduce((chain, update) => chain.then(() => updateProduct({
        variables: {
          product: update,
        },
      })), Promise.resolve());
    } catch(e) {
      showAlert({ message: e.message || e, severity: 'error' });
    }
    stopFetching();
  }, [ products, showAlert, stopFetching, startFetching ]);

  return (
    <React.Fragment>
      <ProgressButton
        iconButton
        active={ loading || fetching }
        icon={ <SyncIcon/> }
        label="Sincronizar"
        onClick={ onRefresh }
      />
      { alert }
    </React.Fragment>
  );
}

export function EditProductButton({ product, size }: { product: Product; size?: 'small' | 'medium' }) {
  return (
    <FormButton<EditProductFormModel>
      Form={ EditProductForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState(toFormModel(product));
        return { model, setModel, isValid: !!model.name };
      }}
      mutation={ updateProductMutation }
      buildVariables={ async formValues => ({
        product: toPersistentModel(product.id, formValues)
      })}
      copy={ EDIT_PRODUCT_COPY }
      iconButton={ true }
      buttonProps={{ size }}
      icon={ <CreateIcon fontSize="inherit" /> }
    />
  )
}

function UploadProductImageButton({ product }: { product: Product }) {
  const { id } = product;

  return (
    <UploadButton<UpdateProductMutationVariables>
      inputId={ `${id}-upload` }
      buildKey={ () => `products/${shortid.generate()}` }
      buildVariables={async (uri) => {
        return ({ product: { id, uri } });
      }}
      mutation={ updateProductMutation }
      copy={ UPLOAD_PRODUCT_IMAGE_COPY }
    />
  )
}

function CreateProductButton() {
  const update = useAddItemUpdate('products', 'product', listProductsQuery);

  return (
    <FormButton<CreateProductFormModel>
      Form={ CreateProductForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({ code: '' });
        return { model, setModel, isValid: !!model.code };
      }}
      mutation={ createProductMutation }
      buildVariables={ async product => ({
        product: await buildProductFromCode(product.code)
      })}
      update={ update }
      copy={ CREATE_PRODUCT_COPY }
      iconButton={ false }
      icon={ <AddIcon/> }
      buttonProps={{ color: 'default' }}
    />
  )
}

function BulkUploadProductsButton() {
  const update = useAddItemUpdate('products', 'product', listProductsQuery);

  return (
    <BulkUploadButton<{ product: { id: string }}>
      inputId='bulk-upload-products'
      buttonProps={{ color: 'default' }}

      findByName={async (fileName) => {
        const product = await findProductByCode(getCodeFromFilename(fileName));

        return product ? { product: { id: product.id } } : null
      }}
      createMutation={ createProductMutation }
      buildCreateVariables={async (fileName) => ({
        product: await buildProductFromCode(getCodeFromFilename(fileName))
      })}

      buildKey={ () => `products/${shortid.generate()}` }

      updateFileMutation={ updateProductMutation }
      buildUpdateFileVariables={async ({ product }, fileName, uri) => ({
        product: {
          id: product.id,
          uri,
        }
      })}
      update={ update }
      copy={ BULK_UPLOAD_PRODUCTS_COPY }
    />
  );
}

function getCodeFromFilename(fileName: string) {
  const labelFragments = fileName.split('.');
  labelFragments.pop();
  return labelFragments.join('.');
}

async function buildProductFromCode(code: string) {
  const apolloClient = await getApolloClient();
  const { data } = await apolloClient.query({
    query: getProductsInfo,
    variables: {
      codes: [ code ],
    },
  });

  const productInfo = data.productsInfo[0] as Product | null;

  if (!productInfo) {
    throw new Error(`Product with code ${ code } not found.`)
  }

  return {
    alias: productInfo.alias,
    code: productInfo.code,
    description: productInfo.description,
    name: productInfo.name,
    price: productInfo.price,
    provider: productInfo.provider,
    stock: productInfo.stock,
    uri: productInfo.uri,
  };
}

async function getProductUpdates(products: Product[]) {
  const apolloClient = await getApolloClient();
  const { data } = await apolloClient.query({
    query: getProductsInfo,
    variables: {
      codes: products.map(product => product.code),
    },
  });

  const infoByCode = keyBy('code', data.productsInfo);

  return products.map(product => infoByCode[product.code] ? {
    id: product.id,
    stock: infoByCode[product.code].stock,
    price: infoByCode[product.code].price,
  } : null).filter(Boolean) as UpdateProductInput[];
}
