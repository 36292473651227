import React, { ChangeEvent, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { EditFormProps } from "../components/edit-dialog";
import { EditProductFormModel, ProductAttributes } from "./form-model";
import { ProductTag } from "@del-alto/shop-util";
import ProductClassSelector from "./product-class-selector";
import ProductKeywordsSelector from "./product-keywords-selector";
import ProductCategoriesSelector from "./product-categories-selector";

type EditProductFormProps = EditFormProps<EditProductFormModel>

export default function EditProductForm({ model, setModel, readOnly }: EditProductFormProps) {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAttribute = useCallback((attribute: ProductAttributes, value: any) => {
    setModel({
      ...model,
      [ attribute ]: value,
    });
  }, [ setModel, model ]);

  const onSetName = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('name', event.target.value), [ setAttribute ]);
  const onSetDescription = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('description', event.target.value), [ setAttribute ]);
  const onSetStockBehaviour = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('stockBehaviour', event.target.value), [ setAttribute ]);
  const onSetOnSale = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('sale', event.target.checked), [ setAttribute ]);
  const onSetOnHotSale = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('hotSale', event.target.checked), [ setAttribute ]);
  const onSetEnabled = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('enabled', event.target.checked), [ setAttribute ]);
  const onSetProductClass = useCallback((value) => setAttribute('class', value), [ setAttribute ]);
  const onSetProductKeywords = useCallback((value) => setAttribute('keywords', value), [ setAttribute ]);
  const onSetProductCategories = useCallback((value) => setAttribute('categories', value), [ setAttribute ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          autoFocus
          margin="normal"
          id="name"
          label="Nombre"
          type="text"
          value={ model.name }
          onChange={ onSetName }
          disabled={readOnly}
          fullWidth
        />
        <ProductClassSelector
          value={ model.class || '' }
          onChange={ onSetProductClass }
          disabled={readOnly}
        />
        <ProductCategoriesSelector
          value={ model.categories || [] }
          onChange={ onSetProductCategories }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Comportamiento de stock</FormLabel>
          <RadioGroup value={ model.stockBehaviour } onChange={ onSetStockBehaviour }>
            <FormControlLabel disabled={readOnly} value="normal" control={<Radio />} label="Normal" />
            { model.available ? (<>
              <FormControlLabel disabled={readOnly} value={ ProductTag.Requestable } control={<Radio />} label="Entrega Inmediata" />
              <FormControlLabel disabled={readOnly} value={ ProductTag.Arrival } control={<Radio />} label="Novedad" />
            </>) : (<>
              <FormControlLabel disabled={readOnly} value={ ProductTag.Requestable } control={<Radio />} label="A pedido" />
              <FormControlLabel disabled={readOnly} value={ ProductTag.Arrival } control={<Radio />} label="Próximamente" />
            </>)}
            <FormControlLabel disabled={readOnly} value={ ProductTag.Discontinued } control={<Radio />} label="Discontinuo" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Switch
              checked={ model.enabled }
              onChange={ onSetEnabled }
              color="primary"
              disabled={readOnly}
            />
          }
          label="Activo"
        />
        <FormControlLabel
          control={
            <Switch
              checked={ model.sale }
              onChange={ onSetOnSale }
              color="primary"
              disabled={readOnly}
            />
          }
          label="Sale"
        />
        <FormControlLabel
          control={
            <Switch
              checked={ model.hotSale }
              onChange={ onSetOnHotSale }
              color="primary"
              disabled={readOnly}
            />
          }
          label="HotSale"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="normal"
          id="description"
          label="Description"
          multiline={true}
          type="text"
          value={ model.description || '' }
          onChange={ onSetDescription }
          disabled={readOnly}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <ProductKeywordsSelector
          value={ model.keywords || [] }
          onChange={ onSetProductKeywords }
          disabled={readOnly}
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
}
