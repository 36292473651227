import Link from "@material-ui/core/Link";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { getCdnUri, getPlaceholder, ImageVariant } from "@del-alto/shop-util";

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      width: 40,
      height: 40,
      borderRadius: 5,
    },
   }),
);

export default function ImagePreview({ uri, className, type }: { uri?: string; className?: string; type?: 'product' | 'scene' }) {
  const classes = useStyles();
  const [ thumbUrl, setThumbUrl ] = React.useState(uri ? getCdnUri(uri, ImageVariant.Thumbnail) : getPlaceholder(type));
  const originalUrl = uri ? getCdnUri(uri, ImageVariant.Original) : getPlaceholder(type);

  const loadOriginal = React.useCallback(() => {
    if (thumbUrl !== originalUrl) {
      setThumbUrl(originalUrl);
    }
  }, [ thumbUrl, setThumbUrl, originalUrl ]);

  return (
    <Link href={ originalUrl } target="_blank" className={ className }>
      <img src={ thumbUrl } className={ classes.image } loading="lazy" onError={loadOriginal}/>
    </Link>
  );
}
