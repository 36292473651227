import React, { CSSProperties } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import { Geometry } from "../types";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { getCdnUri, ImageVariant } from "@del-alto/shop-util";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
    },
    point: {
      position: 'absolute',
      width: 10,
      height: 10,
      borderRadius: 5,
      marginLeft: -5,
      marginTop: -5,
      border: '1px solid #004325',
      backgroundColor: '#00ff9c',
    },
    rect: {
      position: 'absolute',
      opacity: .9,
      backgroundColor: 'rgba(0, 255, 156, .3)',
      border: "1px solid #004325",
    },
  }),
);

type GeometrySelectorProps = {
  src: string;
  value: Geometry | null;
  onChange: (value: Geometry | null) => void;
}

type ImageSize = { scale: number; width: number; height: number; forSrc: string };
type Coords = { x: number; y: number };

export default function GeometrySelector({ src, value, onChange }: GeometrySelectorProps) {
  const [ imageSize, setImageSize ] = React.useState(null as ImageSize | null);
  const [ mouseDownCoords, setMouseDownCoords ] = React.useState(null as Coords | null);
  const classes = useStyles();

  React.useEffect(() => {
    setImageSize(null);
    const img = new Image();

    img.onload = () => {
      setImageSize({
        scale: Math.min(600 / img.width, 400 / img.height),
        width: img.width,
        height: img.height,
        forSrc: src,
      });
    };

    img.src = getCdnUri(src);
  }, [ setImageSize, src ]);

  React.useEffect(() => {
    let cleanupCallback: (() => void) | undefined = undefined;
    if (mouseDownCoords) {
      const callback = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          onChange(null);
          setMouseDownCoords(null);
          e.stopPropagation();
        }
      };
      window.addEventListener('keydown', callback, true);
      cleanupCallback = () => {
        window.removeEventListener('keydown', callback, true);
      };
    }

    return cleanupCallback;
  }, [ !!mouseDownCoords, setMouseDownCoords, onChange ]);

  const scale = imageSize ? imageSize.scale : 1;

  const [ pointStyle, rectStyle ] = React.useMemo(function () {
    if (!value) {
      return [ null, null ];
    }

    const point = {
      left: value.x * scale,
      top: value.y * scale,
    };

    const rect = value.type === 'point' ? null : ({
      left: value.left * scale,
      top: value.top * scale,
      width: value.width * scale,
      height: value.height * scale,
    });

    return [ point, rect ] as (CSSProperties | null)[];
  }, [ value, scale ]);

  const onMouseDownCallback = React.useCallback((e: React.MouseEvent) => {
    setMouseDownCoords(getEventImageCoords(e, scale));
    e.preventDefault();
  }, [ setMouseDownCoords, scale ]);

  const onMouseMoveCallback = React.useCallback((e: React.MouseEvent) => {
    if (mouseDownCoords) {
      const mouseEventPairInfo = buildMouseEventPairInfo(mouseDownCoords, getEventImageCoords(e, scale));
      if (mouseEventPairInfo.isRect) {
        const { x, y, left, top, width, height } = mouseEventPairInfo;
        onChange({ type: 'rect', x, y, left, top, width, height });
      }
    }
  }, [ mouseDownCoords, scale ]);

  const onMouseUpCallback = React.useCallback((e: React.MouseEvent) => {
    if (mouseDownCoords) {
      const mouseUpCoords = getEventImageCoords(e, scale);
      const mouseEventPairInfo = buildMouseEventPairInfo(mouseDownCoords, mouseUpCoords);
      if (mouseEventPairInfo.isPoint) {
        onChange({ ...(value || { type: 'point' }), ...mouseUpCoords });
      }
      setMouseDownCoords(null);
    }
  }, [ onChange, setMouseDownCoords, mouseDownCoords, scale ]);

  if (!imageSize || imageSize.forSrc !== src) {
    return (<CircularProgress size={20} />)
  }

  return (
    <div
      onMouseUp={ onMouseUpCallback }
      onMouseDown={ onMouseDownCallback }
      onMouseMove={ onMouseMoveCallback }
      className={ classes.container }
    >
      <img width={ imageSize.width * scale } src={getCdnUri(src, ImageVariant.Original)} />
      { rectStyle ? <div className={ classes.rect } style={ rectStyle } /> : null }
      { pointStyle ? <div className={ classes.point } style={ pointStyle } /> : null }
    </div>
  );

}

function getEventImageCoords(e: React.MouseEvent, scale: number): Coords {
  const divRect = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
  const x = Math.round((e.clientX - divRect.left) / scale);
  const y = Math.round((e.clientY - divRect.top) / scale);

  return { x, y };
}

type MouseEventPairInfo = {
  x: number;
  y: number;
  left: number;
  top: number;
  width: number;
  height: number;
  isPoint: boolean;
  isRect: boolean;
}

function buildMouseEventPairInfo(startCords: Coords, endCords: Coords): MouseEventPairInfo {
  const { x: startX, y: startY } = startCords;
  const { x: endX, y: endY } = endCords;

  const width = Math.abs(startX - endX);
  const height = Math.abs(startY - endY);
  const left = Math.min(startX, endX);
  const top = Math.min(startY, endY);
  const x = Math.round(left + width / 2);
  const y = Math.round(top + height / 2);

  const isPoint = (width === 0 && height === 0);
  const isRect = (width !== 0 && height !== 0);

  return { x, y, left, top, width, height, isPoint, isRect };
}
