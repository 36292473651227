import gql from 'graphql-tag';
import { useQuery } from "@apollo/react-hooks";
import { CustomerWithOrders } from "../types";

type ListCustomersQueryData = {
  customers: {
    items: CustomerWithOrders[];
  };
}

const listCustomers = gql`
  query listCustomers {
    customers: listCustomers {
      items {
        id
        name
        familyName
        givenName
        email
        phoneNumber
        orders {
          status
          notes
          createdAt
          items {
            count
          }
        }
      }
    }
  }`;

export const useListCustomersQuery = () =>
  useQuery<ListCustomersQueryData>(listCustomers);
