import { OrderStatus } from "../types";

export const ORDER_STATUS_LABELS = {
  [OrderStatus.New]: 'Nuevo',
  [OrderStatus.Draft]: 'Borrador',
  [OrderStatus.Review]: 'Pendiente',
  [OrderStatus.Confirmed]: 'Confirmado',
  [OrderStatus.Completed]: 'Completo',
  [OrderStatus.Canceled]: 'Cancelado',
};
