import TableCell from "@material-ui/core/TableCell";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'right'
    },
  }),
);

export default function CountCell({ items }: { items: Array<unknown> | null }) {
  const classes = useStyles();

  return ( <TableCell className={classes.root}>{ items ? items.length : null }</TableCell> )
}
