import createApolloClient from '@del-alto/appsync-apollo-client';
import ApolloClient from 'apollo-client';
import { OrderWithCustomer } from "./types";

let apolloClient: ApolloClient<unknown>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function dataIdFromObject(data: any) {
  if (data.__typename) {
    // noinspection JSRedundantSwitchStatement
    switch (data.__typename) {
      case 'CustomerOrder': {
        const order: OrderWithCustomer = data;
        if (order.customerId && order.createdAt) {
          return `${ data.__typename }:${ order.customerId }:${ order.createdAt }`;
        }
      }
    }
  }
  return null;
}

function buildApolloClient() {
  return createApolloClient({
    config: {
      dataIdFromObject,
    },
  }) as ApolloClient<unknown>;
}


export default async function getApolloClient() {
  if (!apolloClient) {
    apolloClient = buildApolloClient();
  }

  return apolloClient;
}
