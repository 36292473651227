import PlaylistContentsScreen from './screen';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { Screen } from '../../types';

export default {
  Screen: PlaylistContentsScreen,
  Icon: PlaylistPlayIcon,
  name: 'Tags',
  path: '/playlists/:playlistId',
  root: false,
} as Screen;
