import { Screen } from './types';
import home from './home';
import products from './products';
import scenes, { tags as sceneTags } from './scenes';
import playlists, { contents as playlistContents } from './playlists';
import orders from './orders';
import order from './order';
import customers from './customers';
import customer from './customer';
import links from './links';
import integrations from './integrations';

export default [
  home,
  scenes,
  sceneTags,
  products,
  playlists,
  playlistContents,
  orders,
  order,
  customers,
  customer,
  links,
  integrations,
] as Screen[];
