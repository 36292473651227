import React from 'react';
import { getAllProducts } from "../products/service";
import { Product } from "../types";
import { getCdnUri, generateShopProductLink, getStockBehaviour, isOnSale, ProductTag } from "@del-alto/shop-util";
import { ImageVariant } from "@del-alto/shop-util/lib";

const HEADER = [
  'id',
  'title',
  'description',
  'availability',
  'condition',
  'price',
  'link',
  'image_link',
  'brand',
  'inventory',
];

export default function useExportCatalog(): [ () => void, { loading: boolean } ] {
  const [ loading, setLoading ] = React.useState(false);
  const duplicates = {} as { [code: string]: boolean };

  const exportCatalog = React.useCallback((() => (async () => {
    setLoading(true);

    const allProducts = await getAllProducts();

    const productRows = allProducts
      .map(product => {
        const { condition, availability } = getConditionAndAvailability(product);
        if (!product.price || !product.uri || duplicates[product.code]) {
          return null;
        }

        duplicates[product.code] = true;

        return [
          JSON.stringify(product.code), // id
          JSON.stringify(product.name), // title
          JSON.stringify(phaseCase(product.description || product.name)), // description
          availability, // availability
          condition, // condition
          `${ product.price.toFixed(0) }.0 ARS`, // price
          generateShopProductLink(product.id), // link
          getCdnUri(product.uri, ImageVariant.Full), // image_link
          'Del Alto', // brand
          Math.max(product.stock, 0), // inventory
        ];
      })
      .filter(Boolean) as string[][];

    const csvContent = [ HEADER, ...productRows ]
      .map(cells => cells.join(','))
      .join('\n');

    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `products-${new Date().toISOString()}.csv`);
    link.click();

    setLoading(false);
  })()), [ setLoading ] );

  return [ exportCatalog, { loading }];
}

enum CatalogStock {
  InStock = 'in stock',
  AvailableForOrder = 'available for order',
  PreOrder = 'preorder',
  OutOfStock = 'out of stock',
  Discontinued = 'discontinued',
}

function getConditionAndAvailability(product: Product) {
  const behaviour = getStockBehaviour(product.tags);
  const condition = isOnSale(product.tags) ? 'refurbished' : 'new';
  const availability =
    (behaviour === ProductTag.Arrival && product.stock <= 0 && CatalogStock.PreOrder) ||
    (behaviour === ProductTag.Requestable && product.stock <= 0 && CatalogStock.AvailableForOrder) ||
    (behaviour === ProductTag.Discontinued && product.stock <= 0 && CatalogStock.Discontinued) ||
    (product.stock <= 0 ? CatalogStock.OutOfStock : CatalogStock.InStock);

  return {
    condition,
    availability,
  };
}

function phaseCase(text: string) {
  return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
}
