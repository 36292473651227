import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

type ProgressButtonProps = {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  active: boolean;
  disabled?: boolean;
  iconButton?: boolean;
  icon?: React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text';
};

export default function ProgressButton({ label, icon, iconButton, onClick, active, disabled, variant = 'text' }: ProgressButtonProps) {
  return iconButton ? (
    <IconButton
      onClick={ onClick }
      disabled={ active || disabled }
      aria-label={ label }
    >
      { active ? <CircularProgress size={20} /> : icon }
    </IconButton>
  ) : (
    <Button
      color="primary"
      variant={ variant }
      startIcon={ active ? <CircularProgress size={20} /> : icon }
      onClick={ onClick }
      disabled={ active || disabled }
    >
      { label }
    </Button>
  );
}
