import shortid from 'shortid';
import React  from "react";
import { Link as RouterLink } from "react-router-dom";
import ContentFrame from "../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonsBar from "../components/buttons-bar";
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import { SceneFormModel } from "./types";
import DeleteIconButton from "../components/delete-icon-button";
import {
  createSceneMutation,
  deleteSceneMutation,
  listScenesQuery,
  updateSceneMutation, UpdateSceneMutationVariables,
  useListScenesQuery,
} from "./queries";
import FormButton from "../components/form-button";
import SceneForm from "./form";
import {
  BULK_UPLOAD_SCENE_IMAGES_COPY,
  CREATE_SCENE_COPY,
  deleteSceneCopy,
  EDIT_SCENE_COPY,
  UPLOAD_SCENE_IMAGE_COPY
} from "./copy";
import UploadButton from "../components/upload-button";
import BulkUploadButton from "../components/bulk-upload-button";
import AddIcon from '@material-ui/icons/Add';
import { Scene, SceneWithTags } from "../types";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from '@material-ui/icons/Create';
import ImagePreview from "../components/image-preview";
import useSearchBar, { SearchBarFilterFunction } from "../util/use-search-bar";
import Badge from "@material-ui/core/Badge";
import useInfiniteScrolling from "../util/use-infinite-scrolling";
import CountCell from "../components/count-cell";
import useTableSelection from "../util/use-table-selection";
import BulkUpdateRow from "../components/bulk-update-row";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Dimensions, measureImage, getCdnUri } from "@del-alto/shop-util";
import { SortingHeader, useSorting } from "../util/use-sorting";
import { ImageVariant } from "@del-alto/shop-util/lib";

const searchScene: SearchBarFilterFunction<SceneWithTags> = (scene: SceneWithTags, searchTerm: string) => {
  return scene.label.toLocaleLowerCase().indexOf(searchTerm) !== -1;
};

const useStyles = makeStyles(() =>
  createStyles({
    dimensionsTag: {
      position: 'absolute',
      bottom: -8,
      fontSize: 8,
      whiteSpace: 'nowrap',
    },
  }),
);

type SortAttributes = 'label' | 'tags';

export default function ScenesScreen() {
  const classes = useStyles();
  const { loading, error, data } = useListScenesQuery();
  const [ sorting, sortFn ] = useSorting<SortAttributes, SceneWithTags>('label');

  const allScenes = (loading || error || !data) ? null : data.scenes.items;

  const [ searchBar, filteredScenes ] = useSearchBar(
    allScenes,
    searchScene,
    sortFn,
  );

  const [ toggleSelectionCell, selectAllCell, selection, selectionCountCell ] = useTableSelection(filteredScenes);

  const [ sentinel, scenes, buffered ] = useInfiniteScrolling(filteredScenes, 'td');

  let content: React.ReactElement;
  let actions: React.ReactElement[] | null = null;

  if (loading) {
    content = (<CircularProgress />);
  } else if (error) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
      actions = [
        <CreateSceneButton key='create' />,
        <BulkUploadScenesButton key='upload' />,
      ];
      content = (
      <div>
        <ButtonsBar>
          { searchBar }
        </ButtonsBar>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                { selectAllCell }
                <TableCell>
                  <SortingHeader attribute='label' sorting={ sorting }>
                    Nombre
                  </SortingHeader>
                </TableCell>
                <TableCell>
                  <SortingHeader attribute='tags' sorting={ sorting }>
                    Imagen
                  </SortingHeader>
                </TableCell>
                <CountCell items={ filteredScenes } />
              </TableRow>
            </TableHead>
            <BulkUpdateRow selection={ selection }>
              { () => (
                <React.Fragment>
                  { selectionCountCell }
                  <TableCell colSpan={3} />
                </React.Fragment>
              )}
            </BulkUpdateRow>
            <TableBody>
              { (scenes || []).map((scene: SceneWithTags) => (
                <TableRow key={ scene.id }>
                  { toggleSelectionCell(scene) }
                  <TableCell>
                    <Link href={ generateShopSceneLink(scene.id) } target="_blank">
                      { scene.label }
                    </Link>
                  </TableCell>
                  <TableCell>{ scene.uri ? (
                    <Badge badgeContent={ scene.tags.length } color="secondary">
                      <ImagePreview uri={ scene.uri }/>
                      { scene.size ? (<div className={ classes.dimensionsTag }>{ getDimensionsTag(scene.size) }</div>) : null }
                    </Badge>
                  ) : (
                    <UploadSceneImageButton scene={scene}/>
                  ) }</TableCell>
                  <TableCell>
                    <RouterLink to={ `/scenes/${ scene.id }` }>
                      <IconButton>
                        <FilterCenterFocusIcon />
                      </IconButton>
                    </RouterLink>
                    <EditSceneButton scene={ scene } />
                    <DeleteSceneButton scene={ scene } />
                  </TableCell>
                </TableRow>
              )) }
              <tr style={{ height: 81 * buffered }}>{ sentinel }</tr>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame title="Escenas" actions={actions}>
      { content }
    </ContentFrame>
  );
}

function getDimensionsTag(size: Dimensions) {
  return `${size.width} x ${size.height}`;
}

function generateShopSceneLink(id: string) {
  return `https://www.delaltodigital.com/scenes/${id}`;
}

const refetchQueries = [{ query: listScenesQuery }];

function DeleteSceneButton({ scene }: { scene: Scene }) {
  const variables = React.useMemo(
    () => ({ scene: { id: scene.id } }),
    [ scene ]
  );

  const copy = React.useMemo(
    () => deleteSceneCopy(scene),
    [ scene ]
  );

  return (
    <DeleteIconButton
      mutation={ deleteSceneMutation }
      variables={ variables }
      refetchQueries={ refetchQueries }
      copy={ copy }
    />
  );
}

function UploadSceneImageButton({ scene }: { scene: Scene }) {
  const { id } = scene;

  return (
    <UploadButton<UpdateSceneMutationVariables>
      inputId={ `${id}-upload` }
      buildKey={ () => `scenes/${shortid.generate()}` }
      buildVariables={async (uri) => {
        const size = await measureImage(getCdnUri(uri, ImageVariant.Original));

        return ({
          scene: {
            id,
            uri,
            size,
          },
        });
      }}
      mutation={ updateSceneMutation }
      refetchQueries={ refetchQueries }
      copy={ UPLOAD_SCENE_IMAGE_COPY }
    />
  )
}

function CreateSceneButton() {
  return (
    <FormButton<SceneFormModel>
      Form={ SceneForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({ label: '' });

        return { model, setModel, isValid: !!model.label };
      }}
      mutation={ createSceneMutation }
      buildVariables={ async scene => ({ scene }) }
      refetchQueries={ refetchQueries }
      copy={ CREATE_SCENE_COPY }
      iconButton={ false }
      icon={ <AddIcon/> }
      buttonProps={{ color: 'default' }}
    />
  )
}

function EditSceneButton({ scene: { id, label } }: { scene: Scene }) {
  return (
    <FormButton<SceneFormModel>
      Form={ SceneForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({ label });

        return { model, setModel, isValid: !!model.label };
      }}
      mutation={ updateSceneMutation }
      buildVariables={ async scene => ({
        scene: {
          id,
          label: scene.label
        }
      })}
      refetchQueries={ refetchQueries }
      copy={ EDIT_SCENE_COPY }
      iconButton={ true }
      icon={ <CreateIcon /> }
    />
  )
}

function BulkUploadScenesButton() {
  return (
    <BulkUploadButton<{ scene: { id: string }}>
      inputId='bulk-upload-scenes'
      buttonProps={{ color: 'default' }}

      createMutation={ createSceneMutation }
      buildCreateVariables={async (fileName) => ({
        scene: {
          label: getLabelFromFilename(fileName)
        }
      })}

      buildKey={ () => `scenes/${shortid.generate()}` }

      updateFileMutation={ updateSceneMutation }
      buildUpdateFileVariables={async ({ scene }, fileName, uri) => {
        const size = await measureImage(getCdnUri(uri, ImageVariant.Original));

        return ({
          scene: {
            id: scene.id,
            uri,
            size,
          }
        });
      }}
      refetchQueries={ refetchQueries }
      copy={ BULK_UPLOAD_SCENE_IMAGES_COPY }
    />
  );
}

function getLabelFromFilename(fileName: string) {
  const labelFragments = fileName.split('.');
  labelFragments.pop();
  return labelFragments.join('.');
}
