export const CREATE_PLAYLIST_COPY = {
  title: 'Crear Playlist',
  action: 'Crear',
  cancel: 'Cancelar',
};

export const EDIT_PLAYLIST_COPY = {
  title: 'Editar Playlist',
  label: 'Editar',
  action: 'Guardar',
  cancel: 'Cancelar',
};
