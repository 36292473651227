import React from "react";
import Typography from "@material-ui/core/Typography";
import ContentFrame from "../components/content-frame";

export default function HomeScreen() {
  return (
    <ContentFrame title="Inicio">
      <Typography>Utilice la barra de navegación a la izquierda.</Typography>
    </ContentFrame>
  );
}
