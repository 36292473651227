import TagsScreen from './screen';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import { Screen } from '../../types';

export default {
  Screen: TagsScreen,
  Icon: FilterCenterFocusIcon,
  name: 'Tags',
  path: '/scenes/:sceneId',
  root: false,
} as Screen;
