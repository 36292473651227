import gql from 'graphql-tag';
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Playlist, PlaylistItemSize, PlaylistItemType, PlaylistWithProductDetails } from "../types";
import { productFragment } from "../products/queries";

const playlistFragment = gql`
  fragment PlaylistFragment on Playlist {
    id
    label
    items {
      id
      type
      size
      scene {
        id
        label
        uri
      }
      product {
        id
        code
        name
        uri
      }
    }
  }`;

const playlistProductDetailsFragment = gql`
  fragment PlaylistProductDetailsFragment on Playlist {
    items {
      product {
        ...ProductFragment
      }
    }
  }
  ${ productFragment }
`;

export type ListAllPlaylistsQueryData = { playlists: { items: Playlist[] }};
export const listAllPlaylistsQuery = gql`
  query listPlaylists {
    playlists: listAllPlaylists {
      items {
        ...PlaylistFragment
      }
    }
  }
  ${ playlistFragment }
`;

export type GetPlaylistQueryData = { playlist: PlaylistWithProductDetails }
export type GetPlaylistQueryVariables = { id: string | null }
export const getPlaylistQuery = gql`
  query getPlaylist($id: ID!) {
    playlist: getPlaylist(id: $id) {
      ...PlaylistFragment
      ...PlaylistProductDetailsFragment
    }
  }
  ${ playlistFragment }
  ${ playlistProductDetailsFragment }
`;

type UpdatePlaylistMutationVariables = {
  playlist: {
    id: string;
    label?: string;
    items?: PlaylistItemInput[];
  };
};
export const updatePlaylistMutation = gql`
  mutation updatePlaylist($playlist: UpdatePlaylistInput!) {
    updatePlaylist(input: $playlist) {
      ...PlaylistFragment
    }
  }
  ${ playlistFragment }
`;

type CreatePlaylistMutationVariables = {
  playlist: {
    label: string;
    items: PlaylistItemInput[];
  };
};
export const createPlaylistMutation = gql`
  mutation createPlaylist($playlist: CreatePlaylistInput!) {
    playlist: createPlaylist(input: $playlist) {
      ...PlaylistFragment
    }
  }
  ${ playlistFragment }
`;

export type PlaylistItemInput = {
  type: PlaylistItemType;
  size?: PlaylistItemSize;
  id: string;
};

export const useCreatePlaylistMutation = () => useMutation<{}, CreatePlaylistMutationVariables>(createPlaylistMutation);
export const useUpdatePlaylistMutation = () => useMutation<{}, UpdatePlaylistMutationVariables>(updatePlaylistMutation);
export const useListAllPlaylistsQuery = () => useQuery<ListAllPlaylistsQueryData, {}>(listAllPlaylistsQuery);
export const useGetPlaylistQuery = (variables: GetPlaylistQueryVariables) => useQuery<GetPlaylistQueryData, GetPlaylistQueryVariables>(getPlaylistQuery, { variables, skip: !variables.id });
