import React from 'react';
import { CustomerInfo, Dimensions } from "@del-alto/shop-util";
import { ProductTag, ISO8601DateTime } from "@del-alto/data-types";

export type Screen = {
  Screen: React.ComponentType;
  Icon: React.ComponentType;
  root: boolean;
  name: string;
  path: string;
};

export type Scene = {
  id: string;
  uri: string;
  label: string;
  size?: Dimensions;
}

export type SceneWithTags = Scene & {
  tags: TagWithIndex[];
}

type TagWithIndex = {
  index: number;
}

export type ProductSummary = {
  id: string;
  code: string;
  name: string;
  uri?: string;
}

export type Product =  ProductSummary & {
  alias: string;
  description: string;
  price: number;
  stock: number;
  provider: string;
  tags: ProductTag[] | null;
  class: string;
  keywords: string[] | null;
  categories: string[] | null;
  enabled?: boolean;
}

export type UpdateProductInput = {
  id: string;
  alias?: string;
  code?: string;
  description?: string;
  name?: string;
  price?: number;
  provider?: string;
  stock?: number;
  tags?: ProductTag[];
  uri?: string;
  class?: string;
  keywords?: string[];
  categories?: string[];
  enabled?: boolean;
}

export type UpdateSceneInput = {
  id: string;
  uri?: string;
  label?: string;
  size?: Dimensions;
}

export type SceneWithTagsAndProducts = {
  id: string;
  label: string;
  uri: string;
  tags: TagWithProduct[];
}

export type TagId = {
  sceneId: string;
  index: number;
}

export type Geometry = Point | Rect;

type Point = {
  type: 'point';
  x: number;
  y: number;
}

type Rect = {
  type: 'rect';
  x: number;
  y: number;
  left: number;
  top: number;
  width: number;
  height: number;
}

export type TagWithProduct = TagId & {
  product: Product | null;
  geometry: Geometry | null;
}

export type TagWithNonNullProduct = TagWithProduct & {
  product: Product;
}

export type OrderWithCustomer = Order & {
  customerInfo: CustomerInfo;
}

type BaseOrder = {
  customerId: string;
  createdAt: ISO8601DateTime;
  status: OrderStatus;
  notes: string;
  code: string;
}

export type OrderSummary = BaseOrder & {
  items: OrderItemSummary[];
  customerInfo: CustomerInfo;
}

export type Order = BaseOrder & {
  items: OrderItem[];
}

export type OrderItem = OrderItemSummary & {
  product: Product;
}

export type OrderItemSummary = {
  count: number;
}

export type Customer = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  familyName: string;
  givenName: string;
}

export type CustomerWithOrders = Customer & {
  orders: OrderSummary[];
}

export enum OrderStatus {
  New = 'new',
  Draft = 'draft',
  Review = 'review',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Canceled = 'canceled',
}

export type OrderItemsInput = {
  createdAt: ISO8601DateTime;
  items: OrderItemInput[];
}

export type OrderItemInput = {
  productId: string;
  count: number;
}

export type OrderStatusInput = {
  createdAt: ISO8601DateTime;
  status: OrderStatus;
}

export type OrderNotesInput = {
  createdAt: ISO8601DateTime;
  notes: string;
}

export type Playlist = {
  id: string;
  label: string;
  items: PlaylistItem[];
}

export type PlaylistWithProductDetails = {
  id: string;
  label: string;
  items: PlaylistItemWithProductDetails[];
}

export enum PlaylistItemType {
  Scene = "scene",
  Product = "product",
}

export enum PlaylistItemSize {
  Full = "full",
  Small = "small",
}

export type PlaylistItem = PlaylistProductItem | PlaylistSceneItem;

export type PlaylistItemWithProductDetails = PlaylistProductWithDetailsItem | PlaylistSceneItem;

export type PlaylistProductItem = {
  id: string;
  type: PlaylistItemType.Product;
  product: ProductSummary;
};

export type PlaylistProductWithDetailsItem = {
  id: string;
  type: PlaylistItemType.Product;
  product: Product;
};

export type PlaylistSceneItem = {
  id: string;
  type: PlaylistItemType.Scene;
  scene: Scene;
  size?: PlaylistItemSize;
};
