import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles(() =>
  createStyles({
    expander: {
      transition: 'height 0.5s',
    },
  }),
);

export default function BulkUpdateRow({ selection, children }: { selection: Array<unknown>; children: () => React.ReactNode }) {
  const classes = useStyles();
  const [ showContent, setShowContent ] = React.useState(false);

  React.useEffect(() => {
    if (!showContent && selection.length) {
      setTimeout(() => setShowContent(true), 500);
    }
    if (showContent && !selection.length) {
      setShowContent(false);
    }
  }, [ setShowContent, showContent, selection ]);

  const style = {
    ...(selection.length ? { height: 81 } : { height: 0 }),
  };

  return (
    <TableBody>
      <TableRow className={classes.expander} style={style}>
        { (selection.length && showContent) ? children() : null }
      </TableRow>
    </TableBody>
  );
}
