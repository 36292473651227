import React from 'react';

export default function useInfiniteScrolling<T extends { id: string }>(items: null | T[], el: string): [ React.ReactNode, null | T[], number ] {
  const [ loadedItems, setLoadedItems ] = React.useState(items ? [] as T[] : null);
  const sentinelRef = React.useRef(null as null | HTMLDivElement);

  React.useEffect(() => {
    if (!items) {
      setLoadedItems(null);
      return;
    }
    let itemsToLoad = 15;
    if (loadedItems && items.length >= loadedItems.length) {
      const maintainLength = loadedItems.reduce((reuse, loadedItem, i) => {
        return reuse && items[i].id === loadedItem.id;
      }, true);
      itemsToLoad = maintainLength ? loadedItems.length : 15;
    }
    setLoadedItems(items.slice(0, itemsToLoad));
  }, [ setLoadedItems, items ]);

  React.useEffect(() => {
    const current = (loadedItems || []).length;
    if (!items || !sentinelRef.current || items.length === (loadedItems || []).length) {
      return;
    }

    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) {
        setLoadedItems(items.slice(0, current + 5));
      }
    });

    observer.observe(sentinelRef.current);

    return () => {
      observer.disconnect();
    };
  }, [ setLoadedItems, items, loadedItems, sentinelRef ]);

  const sentinel = React.useMemo(() => React.createElement(el, { ref: sentinelRef }), [ sentinelRef ]);
  const bufferedItems = (items || []).length - (loadedItems || []).length;

  return [ sentinel, loadedItems, bufferedItems ];
}
