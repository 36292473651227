import React, { ChangeEvent, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import { EditFormProps } from "../components/edit-dialog";
import { CreateProductFormModel } from "./form-model";

type CreateProductFormProps = EditFormProps<CreateProductFormModel>

export default function CreateProductForm({ model, setModel, readOnly }: CreateProductFormProps) {

  const onSetCode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setModel({ code: event.target.value });
  }, [ setModel ]);

  return (
    <TextField
      margin="dense"
      id="code"
      label="Código"
      type="text"
      value={ model.code }
      onChange={ onSetCode }
      disabled={readOnly}
      fullWidth
    />
  )
}
