import gql from 'graphql-tag';
import { useQuery } from "@apollo/react-hooks";
import { OrderSummary } from "../types";

type ListOrdersQueryData = {
  orders: {
    items: OrderSummary[];
  };
}

const listOrders = gql`
  query listOrders {
    orders: listCustomerOrders {
      items {
        status
        notes
        createdAt
        code
        items {
          count
        }
        customerInfo {
          name
          phoneNumber
          contactMethod
        }
      }
    }
  }`;

export const useListCustomerOrdersQuery = () =>
  useQuery<ListOrdersQueryData>(listOrders);
