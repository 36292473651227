import React, { ChangeEvent, useCallback } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { BulkAvailability, BulkEditProductFormModel, BulkProductAttributes } from "./form-model";
import { ProductTag } from "@del-alto/shop-util";
import { EditFormProps } from "../components/edit-dialog";
import Grid from "@material-ui/core/Grid";
import ProductClassSelector from "./product-class-selector";
import ProductKeywordsSelector from "./product-keywords-selector";
import ProductCategoriesSelector from "./product-categories-selector";
import { Product } from "../types";

type BulkEditProductFormProps = EditFormProps<BulkEditProductFormModel> & {
  products: Product[];
};

export default function BulkEditProductForm({ model, setModel, readOnly, products }: BulkEditProductFormProps) {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAttribute = useCallback((attribute: BulkProductAttributes, value: any) => {
    setModel({
      ...model,
      [ attribute ]: value,
    });
  }, [ setModel, model ]);

  const onSetStockBehaviour = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('stockBehaviour', event.target.value), [ setAttribute ]);
  const onSetOnSale = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('sale', event.target.value === "true"), [ setAttribute ]);
  const onSetOnHotSale = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('hotSale', event.target.value === "true"), [ setAttribute ]);
  const onSetEnabled = useCallback((event: ChangeEvent<HTMLInputElement>) => setAttribute('enabled', event.target.value === "true"), [ setAttribute ]);
  const onSetProductClass = useCallback((value: string) => setAttribute('class', value), [ setAttribute ]);
  const onSetAddProductKeywords = useCallback((value: string[]) => setAttribute('addKeywords', value), [ setAttribute ]);
  const onSetRemoveProductKeywords = useCallback((value: string[]) => setAttribute('removeKeywords', value), [ setAttribute ]);
  const onSetAddProductCategories = useCallback((value: string[]) => setAttribute('addCategories', value), [ setAttribute ]);
  const onSetRemoveProductCategories = useCallback((value: string[]) => setAttribute('removeCategories', value), [ setAttribute ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <ProductClassSelector
          value={ model.class || '' }
          onChange={ onSetProductClass }
          disabled={readOnly}
          margin="dense"
        />
        <ProductCategoriesSelector
          label="Agregar categorias"
          value={ model.addCategories || [] }
          onChange={ onSetAddProductCategories }
          disabled={readOnly}
        />
        <ProductCategoriesSelector
          label="Quitar categorias"
          value={ model.removeCategories || [] }
          onChange={ onSetRemoveProductCategories }
          disabled={readOnly}
          allowCreation={false}
          availableProducts={products}
        />
      </Grid>
      <Grid container item xs={6} spacing={3}>
        <Grid item xs={8}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Comportamiento de stock</FormLabel>
            <RadioGroup value={ model.stockBehaviour } onChange={ onSetStockBehaviour }>
              <FormControlLabel disabled={readOnly} value="normal" control={<Radio />} label="Normal" />
              {(model.available === BulkAvailability.Available && <>
                <FormControlLabel disabled={readOnly} value={ ProductTag.Requestable } control={<Radio />} label="Entrega Inmediata" />
                <FormControlLabel disabled={readOnly} value={ ProductTag.Arrival } control={<Radio />} label="Novedad" />
              </>) || (model.available === BulkAvailability.Unavailable && <>
                <FormControlLabel disabled={readOnly} value={ ProductTag.Requestable } control={<Radio />} label="A pedido" />
                <FormControlLabel disabled={readOnly} value={ ProductTag.Arrival } control={<Radio />} label="Próximamente" />
              </>)}
              <FormControlLabel disabled={readOnly} value={ ProductTag.Discontinued } control={<Radio />} label="Discontinuo" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Activo</FormLabel>
            <RadioGroup value={ String(model.enabled) } onChange={ onSetEnabled }>
              <FormControlLabel disabled={readOnly} value={"true"} control={<Radio />} label="Si" />
              <FormControlLabel disabled={readOnly} value={"false"} control={<Radio />} label="No" />
            </RadioGroup>
            <FormLabel component="legend">Sale</FormLabel>
            <RadioGroup value={ String(model.sale) } onChange={ onSetOnSale }>
              <FormControlLabel disabled={readOnly} value={"true"} control={<Radio />} label="Si" />
              <FormControlLabel disabled={readOnly} value={"false"} control={<Radio />} label="No" />
            </RadioGroup>
            <FormLabel component="legend">HotSale</FormLabel>
            <RadioGroup value={ String(model.hotSale) } onChange={ onSetOnHotSale }>
              <FormControlLabel disabled={readOnly} value={"true"} control={<Radio />} label="Si" />
              <FormControlLabel disabled={readOnly} value={"false"} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ProductKeywordsSelector
            label="Agregar palabras clave"
            value={ model.addKeywords || [] }
            onChange={ onSetAddProductKeywords }
            disabled={readOnly}
          />
          <ProductKeywordsSelector
            label="Quitar palabras clave"
            value={ model.removeKeywords || [] }
            onChange={ onSetRemoveProductKeywords }
            disabled={readOnly}
            allowCreation={false}
            availableProducts={products}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
