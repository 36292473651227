import AWS from "aws-sdk";
import { AWS_BUCKET_REGION } from "./constants";

let s3: AWS.S3;

export function getS3() {
  if (!s3) {
    s3 = new AWS.S3({ region: AWS_BUCKET_REGION });
  }
  return s3;
}
