import history from './history';
import Auth, { IdentityProvider } from '@del-alto/auth';

import {
  AWS_COGNITO_CODE_QUERY_PARAM,
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  CLIENT_ID,
  REDIRECT_URI,
  AUTH_DOMAIN,
} from "./constants";

const REFRESH_TOKEN_DURATION = 180 * 24 * 3600 * 1000; // 180 days

const queryParams = (() => {
  const search = window.location.search || '';
  const hash = window.location.hash || '';

  return new URLSearchParams(search + hash.replace('#', search ? '&' : '?'));
})();

export default {
  initialize
};

async function initialize() {
  const queryParamCode = queryParams.get(AWS_COGNITO_CODE_QUERY_PARAM);

  if (queryParamCode) {
    history.replace(window.location.pathname);
  }

  return Auth.initialize({
    region: AWS_REGION,
    identityPoolId: AWS_IDENTITY_POOL_ID,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    authDomain: AUTH_DOMAIN,
    useUnAuthCredentials: false,
    refreshTokenDurationMillis: REFRESH_TOKEN_DURATION,
  }, queryParamCode)
    .then((credentials) => {
      if (!credentials) {
        window.location.href = Auth.getLoginUrl(IdentityProvider.Cognito);
        throw new Error();
      }
    });
}
