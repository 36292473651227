import CustomersScreen from './screen';
import ContactsIcon from '@material-ui/icons/Contacts';
import { Screen } from '../types';

export default {
  Screen: CustomersScreen,
  Icon: ContactsIcon,
  name: 'Clientes',
  path: '/customers',
  root: true,
} as Screen;
