import gql from 'graphql-tag';
import { useQuery } from "@apollo/react-hooks";
import { SceneWithTagsAndProducts } from "../../types";
import { productFragment } from "../../products/queries";

export const getSceneWithTagsQuery = gql`
  query getSceneTags($sceneId: ID!) {
    scene: getScene(id: $sceneId) {
      id
      label
      uri
      tags {
        sceneId
        index
        geometry {
          type
          x
          y
          left
          top
          width
          height
        }
        product {
          ...ProductFragment
        }
      }
    }
  }
  ${ productFragment }
`;

type GetSceneWithTagsQueryData = { scene: SceneWithTagsAndProducts }
type GetSceneWithTagsQueryVariables = { sceneId: string | null }
export const useGetSceneWithTagsQuery = (variables: GetSceneWithTagsQueryVariables) =>
  useQuery<GetSceneWithTagsQueryData, GetSceneWithTagsQueryVariables>(getSceneWithTagsQuery, {
    skip: !variables.sceneId,
    variables
  });

export const deleteSceneTagMutation = gql`
  mutation deleteSceneTag($sceneTag: DeleteSceneTagInput!) {
    deleteSceneTag(input: $sceneTag) {
      sceneId
      index
    }
  }`;

export const updateSceneTagMutation = gql`
  mutation updateSceneTag($sceneTag: UpdateSceneTagInput!) {
    updateSceneTag(input: $sceneTag) {
      sceneId
      index
    }
  }`;

export const createSceneTagMutation = gql`
  mutation createSceneTag($sceneTag: CreateSceneTagInput!) {
    sceneTag: createSceneTag(input: $sceneTag) {
      sceneId
      index
    }
  }`;
