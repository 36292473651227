import { useListScenesQuery } from "./queries";
import React from "react";
import { Scene } from "../types";
import SearchBox from "../components/search-box";

const stringify =  (scene: Scene) => scene.label;

type ScenesSearchProps = {
  onSelectScene: (product: Scene) => void;
  label: string;
  disabled: boolean;
  autoFocus: boolean;
  value: Scene | null;
}

export default function SceneSearch({ value, onSelectScene, label, disabled, autoFocus }: ScenesSearchProps) {
  const { data } = useListScenesQuery();

  const scenes = React.useMemo(() => (
    data && data.scenes.items || []
  ), [ data ]);

  return (
    <SearchBox<Scene>
      options={ scenes }
      onSelect={ onSelectScene }
      value={ value }
      disabled={ disabled }
      getOptionLabel={(option) => option.label}
      stringify={ stringify }
      label={ label }
      autoFocus={ autoFocus }
    />
  );
}
