import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from "react-router-dom";
import {
  useListCustomerOrdersQuery
} from "./queries";
import { ORDER_STATUS_LABELS } from "../order/copy";
import { OrderSummary } from "../types";
import ContentFrame from "../components/content-frame";
import ContactMethodIcon from "../customers/contact-method";

export default function OrdersScreen() {
  const { loading, error, data } = useListCustomerOrdersQuery();

  const orders: null | OrderSummary[] = (loading || error) ? null : data!.orders.items;

  let content: React.ReactElement;

  if (loading) {
    content = (<CircularProgress />);
  } else if (error || !orders) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    content = (
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Orden
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Productos
                </TableCell>
                <TableCell>
                  Notas
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                const customer = order.customerInfo;
                const count = order.items.reduce((memo, item) => memo + item.count, 0);
                return (
                  <TableRow key={ order.code }>
                    <TableCell>
                      <RouterLink to={ `/orders/${order.code}` }>
                        { order.code }
                      </RouterLink>
                    </TableCell>
                    <TableCell>
                      <div>{ customer.name }</div>
                      <div>
                        <ContactMethodIcon contactMethod={ customer.contactMethod }/>
                        { customer.phoneNumber }
                      </div>
                    </TableCell>
                    <TableCell>{ ORDER_STATUS_LABELS[ order.status ] }</TableCell>
                    <TableCell>{ count }</TableCell>
                    <TableCell>{ order.notes }</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame title="Pedidos">
      { content }
    </ContentFrame>
  );
}
