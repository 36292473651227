import React from 'react';
import { Product } from "../types";

export function useAllClasses(allProducts: null | Product[]) {
  return React.useMemo(() => {
    if (!allProducts) {
      return [];
    }
    const classes = new Set<string>();
    allProducts.forEach(product => {
      classes.add(product.class);
    });
    return Array.from(classes).filter(Boolean);
  }, [ allProducts ]);
}

export function useAllKeywords(allProducts: null | Product[]) {
  return React.useMemo(
    () => collectProperty(allProducts, 'keywords'),
    [ allProducts ]
  );
}

export function useAllCategories(allProducts: null | Product[]) {
  return React.useMemo(
    () => collectProperty(allProducts, 'categories'),
    [ allProducts ]
  );
}

export function useAllProviders(allProducts: null | Product[]) {
  return React.useMemo(
    () => collectProperty(
      allProducts && allProducts.map(({ code }) => ({ providers: [ code.split('-')[0] ] })),
      'providers',
    ),
    [ allProducts ],
  );
}

function collectProperty<P extends string, T>(elements: null | { [key in P]: (T[] | null) }[], propertyName: P): T[] {
  if (!elements) {
    return [];
  }
  const collect = new Set<T>();
  elements.forEach(product => {
    const productValues: T[] | null = product[propertyName];
    (productValues || []).forEach(value => collect.add(value));
  });
  return Array.from(collect);
}
