import gql from 'graphql-tag';
import { useQuery } from "@apollo/react-hooks";
import { Link } from "./types";

export type GetLinkQueryData = { links: Link[] }
export type GetLinkQueryVariables = { laid: string | null }
export const getLinkQuery = gql`
  query getLink($laid: String!) {
    links: getLink(laid: $laid) {
      customerId
      views
    }
  }
`;

export const useGetLinkQuery = (laid: string | null) =>
  useQuery<GetLinkQueryData, GetLinkQueryVariables>(getLinkQuery, {
    variables: { laid },
    skip: !laid,
    fetchPolicy: 'no-cache',
  });
