import gql from 'graphql-tag';
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Scene, SceneWithTags, UpdateSceneInput } from "../types";

export type ListScenesQueryData = { scenes: { items: SceneWithTags[] } };
export const listScenesQuery = gql`
  query listScenes {
    scenes: listAllScenes {
      items {
        id
        label
        uri
        size {
          width
          height
        }
        tags {
          index
        }
      }
    }
  }`;

export type GetSceneQueryData = { scene: Scene }
export type GetSceneQueryVariables = { id: string | null }
export const getSceneQuery = gql`
  query getScene($id: ID!) {
    scene: getScene(id: $id) {
      id
      label
      uri
      size {
        width
        height
      }
    }
  }`;

export const deleteSceneMutation = gql`
  mutation deleteScene($scene: DeleteSceneInput!) {
    deleteScene(input: $scene) {
      id
    }
  }`;

export type UpdateSceneMutationVariables = {
  scene: UpdateSceneInput;
}
export const updateSceneMutation = gql`
  mutation updateScene($scene: UpdateSceneInput!) {
    updateScene(input: $scene) {
      id
      label
      uri
      size {
        width
        height
      }
    }
  }`;

export const createSceneMutation = gql`
  mutation createScene($scene: CreateSceneInput!) {
    scene: createScene(input: $scene) {
      id
      label
      uri
      size {
        width
        height
      }
    }
  }`;

export const useListScenesQuery = () => useQuery<ListScenesQueryData>(listScenesQuery);
export const useUpdateSceneMutation = () => useMutation<GetSceneQueryData, UpdateSceneMutationVariables>(updateSceneMutation);
