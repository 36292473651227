import React from 'react';
import { DocumentNode } from 'graphql';
import { useQuery } from "@apollo/react-hooks";
import getApolloClient from "../apollo-client";
import ApolloClient from 'apollo-client';
import { listProductsQuery } from "../products/queries";

type ScanQueryData<
  TKey extends string,
  TTypeName extends string,
  TItem extends { id: string },
> = {
    [P in TKey]: {
      items: TItem[];
      nextToken?: string;
      __typename: TTypeName;
    }
  };

type ItemMutationData<
  TItemKey extends string,
  TItem extends { id: string },
> = {
  [P in TItemKey]: TItem
};

type ScanQueryVariables = {
  nextToken?: string;
}

const ALL_DATA_TOKEN = 'ALL';

export default function useFullScanQuery<
  TKey extends string,
  TTypeName extends string,
  TItem extends { id: string },
  TData extends ScanQueryData<TKey ,TTypeName, TItem>,
>(key: TKey, connectionTypeName: TTypeName, query: DocumentNode) {
  const [ ready, setReady ] = React.useState(false);

  React.useEffect(() => { (async () => {
    const apolloClient = await getApolloClient();

    let hasData;
    try {
      hasData = !!apolloClient.readQuery<TData, ScanQueryVariables>({
        query: listProductsQuery,
        variables: {
          nextToken: ALL_DATA_TOKEN,
        },
      });
    } catch(ignore) {
      hasData = false;
    }

    if (!hasData) {
      const items = await scanAll<TKey, TTypeName, TItem, TData>(key, query, []);
      apolloClient.writeQuery<TData, ScanQueryVariables>({
        query,
        variables: {
          nextToken: ALL_DATA_TOKEN,
        },
        data: {
          [ key ]: {
            items,
            nextToken: ALL_DATA_TOKEN,
            __typename: connectionTypeName,
          },
        } as TData,
      });
    }

    setReady(true);
  })() }, [ key, connectionTypeName, query ]);

  return {
    ...useQuery<TData, ScanQueryVariables>(query, {
      variables: {
        nextToken: ALL_DATA_TOKEN,
      },
      fetchPolicy: "cache-only",
      skip: !ready,
    }),
    ready,
  };
}

async function scanAll<
  TKey extends string,
  TTypeName extends string,
  TItem extends { id: string },
  TData extends ScanQueryData<TKey, TTypeName, TItem>,
>(key: TKey, query: DocumentNode, collect: TItem[], startToken?: string): Promise<TItem[]> {
  const apolloClient = await getApolloClient();

  const { data } = await apolloClient.query<TData, ScanQueryVariables>({
    query,
    variables: { nextToken: startToken },
  });

  const { nextToken, items } = data[key];

  collect.push(...items);

  if (nextToken) {
    await scanAll(key, query, collect, nextToken);
  }

  return collect;
}

export function useRemoveItemUpdate<
  TKey extends string,
  TTypeName extends string,
  TItem extends { id: string },
  TData extends ScanQueryData<TKey, TTypeName, TItem>,
>(key: TKey, query: DocumentNode, item: TItem) {
  return React.useCallback((apolloClient: ApolloClient<unknown>) => {
    const data = apolloClient.readQuery<TData, ScanQueryVariables>({
      query: listProductsQuery,
      variables: {
        nextToken: ALL_DATA_TOKEN,
      },
    })!;

    apolloClient.writeQuery<TData, ScanQueryVariables>({
      query,
      variables: {
        nextToken: ALL_DATA_TOKEN,
      },
      data: {
        [ key ]: {
          items: data[key].items.filter(i => i.id !== item.id),
          nextToken: ALL_DATA_TOKEN,
          __typename: data[key].__typename,
        },
      } as TData,
    });
  }, [ key, query, item ])
}

export function useAddItemUpdate<
  TCollectionKey extends string,
  TItemKey extends string,
  TTypeName extends string,
  TItem extends { id: string },
  TData extends ScanQueryData<TCollectionKey, TTypeName, TItem>,
  TItemMutationData extends ItemMutationData<TItemKey, TItem>,
>(collectionKey: TCollectionKey, itemKey: TItemKey, query: DocumentNode) {
  return React.useCallback((apolloClient: ApolloClient<unknown>, { data }: { data: TItemMutationData }) => {
    const cacheData = apolloClient.readQuery<TData, ScanQueryVariables>({
      query: listProductsQuery,
      variables: {
        nextToken: ALL_DATA_TOKEN,
      },
    })!;

    apolloClient.writeQuery<TData, ScanQueryVariables>({
      query,
      variables: {
        nextToken: ALL_DATA_TOKEN,
      },
      data: {
        [ collectionKey ]: {
          items: [  data[itemKey], ...cacheData[collectionKey].items ],
          nextToken: ALL_DATA_TOKEN,
          __typename: cacheData[collectionKey].__typename,
        },
      } as TData,
    });
  }, [ collectionKey, itemKey, query ])
}
