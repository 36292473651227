import React from "react";
import ContentFrame from "../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  useListCustomersQuery
} from "./queries";
import { Link as RouterLink } from "react-router-dom";
import { CustomerWithOrders } from "../types";

export default function OrdersScreen() {
  const { loading, error, data } = useListCustomersQuery();

  const users: null | CustomerWithOrders[] = (loading || error) ? null : data!.customers.items;

  const customers = React.useMemo(() => (
    users && users.filter(user => user.orders.length).sort((a, b) => a.orders.length - b.orders.length)
  ), [ users ]);

  let content: React.ReactElement;

  if (loading) {
    content = (<CircularProgress />);
  } else if (error) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    content = (
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Pedidos
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers!.map((customer) => {
                return (
                  <TableRow key={ customer.id }>
                    <TableCell>
                      <RouterLink to={ `/customers/${ customer.id }` }>
                        { customer.name || customer.email || customer.phoneNumber }
                      </RouterLink>
                    </TableCell>
                    <TableCell>{ customer.orders.length }</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <ContentFrame title="Pedidos">
      { content }
    </ContentFrame>
  );
}
