import LinksScreen from './screen';
import LinkIcon from '@material-ui/icons/Link';
import { Screen } from '../types';

export default {
  Screen: LinksScreen,
  Icon: LinkIcon,
  name: 'Links',
  path: '/links',
  root: true,
} as Screen;
