import React from "react";
import { useParams } from "react-router-dom";
import ContentFrame from "../../components/content-frame";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import classnames from 'classnames';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';
import { PlaylistItemInput, updatePlaylistMutation, useGetPlaylistQuery, useUpdatePlaylistMutation, } from "../queries";
import ButtonsBar from "../../components/buttons-bar";
import {
  Playlist,
  PlaylistItemSize,
  PlaylistItemType,
  PlaylistItemWithProductDetails,
  PlaylistProductWithDetailsItem,
  PlaylistSceneItem
} from "../../types";
import ImagePreview from "../../components/image-preview";
import { EditProductButton, SyncProductButton } from "../../products/screen";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ProductChip from "../../products/product-chip";
import { ProductPrice } from "../../products/product-price";
import { ProductStock } from "../../products/product-stock";
import ProductSearch from "../../products/product-search";
import SceneSearch from "../../scenes/scene-search";
import ProgressButton from "../../components/progress-button";
import DeleteIcon from '@material-ui/icons/Delete';
import useAlert from "../../util/use-alert";
import FormButton from "../../components/form-button";
import CreateIcon from '@material-ui/icons/Create';
import { EDIT_PLAYLIST_COPY } from "../copy";
import { PlaylistFormModel } from "../types";
import PlaylistForm from "../form";

const useStyles = makeStyles(() =>
  createStyles({
    editProductButtonContainer: {
      display: 'inline-block',
      marginLeft: 5,
    },
    productChip: {
      marginRight: 10,
    },
    tableRow: {
      width: '100%',
      backgroundColor: 'white',
      display: 'table',
    },
    tableRowDragging: {
      borderTop: "1px solid rgba(224, 224, 224, 1)",
    },
    handleCell: {
      width: 57,
    },
    imageCell: {
      width: 82
    },
    sceneLabelCell: {
      width: 'calc(100% - 271px)'
    },
    productNameCell: {
      width: 'calc(100% - 515px)'
    },
    errorCell: {
      width: 'calc(100% - 189px)'
    },
    productPriceCell: {
      width: 112,
      textAlign: 'right',
    },
    stockCell: {
      width: 132,
    },
    actionsCell: {
      width: 132,
    },
  }),
);

export default function PlaylistContentsScreen() {
  const classes = useStyles();
  const { playlistId } = useParams() as { playlistId: string };
  const { loading, error, data } = useGetPlaylistQuery({ id: playlistId });
  const [ updatePlaylist ] = useUpdatePlaylistMutation();

  let content: React.ReactElement;
  let actions: React.ReactElement[] | null = null;

  const playlist = data && data.playlist;
  let title = "Contenidos";

  const [ sortedItems, setSortedItems ] = React.useState(null as PlaylistItemWithProductDetails[] | null);

  const { onRemoveItem, onResizeItem, onAddProduct, onAddScene, onDragEnd } = React.useMemo(() => {
    if (!playlist) {
      return { };
    }

    function toInputItem(item: PlaylistItemWithProductDetails) {
      return {
        id: item.id,
        type: item.type,
        size: item.type === PlaylistItemType.Scene ? item.size : undefined,
      };
    }

    const inputItems: PlaylistItemInput[] = (playlist.items || []).map(toInputItem);

    const setItems = (items: PlaylistItemInput[]) =>
      updatePlaylist({
        variables: {
          playlist: {
            id: playlistId,
            items,
          },
        },
      });

    const addItem = (type: PlaylistItemType, id: string) => setItems([ ...inputItems, { type, id } ]);

    return {
      onRemoveItem: (index: number) => setItems([
        ...inputItems.slice(0, index),
        ...inputItems.slice(index + 1),
      ]),
      onResizeItem: (index: number, size: PlaylistItemSize) => setItems(
        inputItems.map((item, idx) => (idx === index) ? {
          ...item,
          size,
        } : item
      )),
      onDragEnd: async ({ source, destination }: DropResult) => {
        if (destination) {
          const draggedItem = playlist.items[source.index];
          const result = playlist.items.filter((item, i) => i !== source.index);
          result.splice(destination.index, 0, draggedItem);
          setSortedItems(result);
          await setItems(result.map(toInputItem));
          setSortedItems(null);
        }
      },
      onAddProduct: ({ id }: { id: string }) => addItem(PlaylistItemType.Product, id),
      onAddScene: ({ id }: { id: string }) => addItem(PlaylistItemType.Scene, id),
    };
  }, [ playlist && playlist.items, updatePlaylist ]);

  if (loading) {
    content = (<CircularProgress />);
  } else if (error || !playlist) {
    content = (
      <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
    );
  } else {
    const playlistItems = sortedItems || (playlist.items || []);
    title = `Contenidos de ${playlist.label}`;
    actions = [
      <EditPlaylistButton key='edit' playlist={ playlist } />,
    ];
    content = (
      <div>
        <ButtonsBar>
          <ProductSearch
            value={ null }
            onSelectProduct={ onAddProduct! }
            label="Agregar producto"
            showStock={ true }
            disabled={ false }
            autoFocus={true}
          />
          <SceneSearch
            value={ null }
            onSelectScene={ onAddScene! }
            label="Agregar escena"
            disabled={ false }
            autoFocus={ false }
          />
        </ButtonsBar>
        <DragDropContext onDragEnd={ onDragEnd! }>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={ classes.tableRow }>
                  <TableCell className={ classes.handleCell }/>
                  <TableCell className={ classes.imageCell }/>
                  <TableCell className={ classes.productNameCell} >Nombre</TableCell>
                  <TableCell className={ classes.stockCell }>Stock</TableCell>
                  <TableCell className={ classes.productPriceCell }>Precio</TableCell>
                  <TableCell className={ classes.actionsCell} />
                </TableRow>
              </TableHead>
              <Droppable droppableId="table">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody
                    ref={ droppableProvided.innerRef }
                    { ...droppableProvided.droppableProps }
                  >
                    { playlistItems.map((item, i) => (
                        <Draggable
                          draggableId={item.id}
                          index={i}
                          key={item.id}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot,
                          ) => (
                            <DraggableTableRow
                              item={ item }
                              onRemoveItem={ onRemoveItem! }
                              onResizeItem={ onResizeItem! }
                              key={ item.id }
                              index={ i }
                              draggableProvided={ provided }
                              isDragging={ snapshot.isDragging }
                            />
                          )}
                        </Draggable>
                      ))
                    }
                    { droppableProvided.placeholder }
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      </div>
    );
  }

  return (
    <ContentFrame title={ title } actions={ actions }>
      { content }
    </ContentFrame>
  );
}

type DraggableTableRow = {
  item: PlaylistItemWithProductDetails;
  onRemoveItem: RemoveItemCallback;
  onResizeItem: ResizeItemCallback;
  index: number;
  isDragging: boolean;
  draggableProvided: DraggableProvided;
}
function DraggableTableRow ({ item, onRemoveItem, onResizeItem, index, draggableProvided, isDragging }: DraggableTableRow) {
  const classes = useStyles();

  let content = null;
  if (item.type === 'scene') {
    content = item.scene ?
      (<SceneItemRow key={item.id} item={ item } index={ index } onRemoveItem={ onRemoveItem } onResizeItem={ onResizeItem } /> ) :
      (<BrokenItemRow key={item.id} index={ index } onRemoveItem={ onRemoveItem } />);
  } else if (item.type === 'product') {
    content = item.product ?
      (<ProductItemRow key={item.id} item={ item } index={ index } onRemoveItem={ onRemoveItem } />) :
      (<BrokenItemRow key={item.id} index={ index } onRemoveItem={ onRemoveItem } />);
  }

  return (
    <TableRow key={ item.id }
      ref={ draggableProvided.innerRef }
      className={classnames(classes.tableRow, isDragging && classes.tableRowDragging) }
      { ...draggableProvided.draggableProps }
    >
      <TableCell className={ classes.handleCell } { ...draggableProvided.dragHandleProps }>
        <DragIndicatorIcon />
      </TableCell>
      { content }
    </TableRow>
  );
}

function EditPlaylistButton({ playlist: { id, label } }: { playlist: Playlist }) {
  return (
    <FormButton<PlaylistFormModel>
      Form={ PlaylistForm }
      useFormModel={() => {
        const [ model, setModel ] = React.useState({ label });

        return { model, setModel, isValid: !!model.label };
      }}
      mutation={ updatePlaylistMutation }
      buildVariables={ async playlist => ({
        playlist: {
          id,
          label: playlist.label
        }
      })}
      copy={ EDIT_PLAYLIST_COPY }
      iconButton={ false }
      icon={ <CreateIcon /> }
      buttonProps={{ color: 'default' }}
    />
  );
}

function BrokenItemRow({ index, onRemoveItem }: { index: number; onRemoveItem: RemoveItemCallback }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableCell colSpan={ 4 } className={ classes.errorCell }>
        <ErrorIcon />
      </TableCell>
      <TableCell className={ classes.actionsCell }>
        <RemoveItemButton index={ index } onRemoveItem={ onRemoveItem }/>
      </TableCell>
    </React.Fragment>
  );
}

type SceneItemRowProps = {
  index: number;
  item: PlaylistSceneItem;
  onRemoveItem: RemoveItemCallback;
  onResizeItem: ResizeItemCallback;
};
function SceneItemRow({ index, item, onRemoveItem, onResizeItem }: SceneItemRowProps) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableCell className={ classes.imageCell }>
        <ImagePreview uri={ item.scene.uri } type='scene' />
      </TableCell>
      <TableCell colSpan={3} className={ classes.sceneLabelCell }>
        { item.scene.label }
      </TableCell>
      <TableCell className={ classes.actionsCell }>
        <ResizeItemButton index={ index } onResizeItem={ onResizeItem } size={ item.size || PlaylistItemSize.Full }/>
        <RemoveItemButton index={ index } onRemoveItem={ onRemoveItem }/>
      </TableCell>
    </React.Fragment>
  );
}

function ProductItemRow({ index, item, onRemoveItem }: { index: number; item: PlaylistProductWithDetailsItem; onRemoveItem: RemoveItemCallback }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableCell className={ classes.imageCell }>
        <ImagePreview uri={ item.product.uri } type='product'/>
      </TableCell>
      <TableCell className={ classes.productNameCell }>
        <ProductChip product={ item.product } showStock={false} className={classes.productChip}/>
        { item.product.name }
        <div className={classes.editProductButtonContainer}>
          <EditProductButton product={ item.product } size='small'/>
        </div>
      </TableCell>
      <TableCell className={ classes.stockCell }><ProductStock product={item.product} /></TableCell>
      <TableCell className={ classes.productPriceCell }><ProductPrice product={item.product} /></TableCell>
      <TableCell className={ classes.actionsCell }>
        <SyncProductButton product={ item.product } />
        <RemoveItemButton index={ index } onRemoveItem={ onRemoveItem }/>
      </TableCell>
    </React.Fragment>
  );
}

type ResizeItemCallback = (index: number, size: PlaylistItemSize ) => Promise<unknown>;

function ResizeItemButton({ index, onResizeItem, size }: { index: number; onResizeItem: ResizeItemCallback; size: PlaylistItemSize }) {
  const [ alert, showAlert ] = useAlert();
  const [ loading, setLoading ] = React.useState(false);

  const onResize = React.useCallback(() => (async () => {
    setLoading(true);
    try {
      await onResizeItem(index, size === PlaylistItemSize.Full ? PlaylistItemSize.Small : PlaylistItemSize.Full);
    } catch (e) {
      showAlert({ message: e.message || e, severity: 'error' });
    }
    setLoading(false);
  })(), [ showAlert, setLoading, index, onResizeItem ]);

  return (
    <React.Fragment>
      <ProgressButton
        iconButton
        active={ loading }
        icon={ size === PlaylistItemSize.Small ? <FullscreenExitIcon/> : <FullscreenIcon/> }
        label="Cambiar tamaño"
        onClick={ onResize }
      />
      { alert }
    </React.Fragment>
  );
}


type RemoveItemCallback = (index: number) => Promise<unknown>;

function RemoveItemButton({ index, onRemoveItem }: { index: number; onRemoveItem: RemoveItemCallback }) {
  const [ alert, showAlert ] = useAlert();
  const [ loading, setLoading ] = React.useState(false);

  const onDelete = React.useCallback(() => (async () => {
    setLoading(true);
    try {
      await onRemoveItem(index);
    } catch (e) {
      showAlert({ message: e.message || e, severity: 'error' });
    }
    setLoading(false);
  })(), [ showAlert, setLoading, index, onRemoveItem ]);

  return (
    <React.Fragment>
      <ProgressButton
        iconButton
        active={ loading }
        icon={ <DeleteIcon/> }
        label="Eliminar"
        onClick={ onDelete }
      />
      { alert }
    </React.Fragment>
  );
}
