import ProductsScreen from './screen';
import RedeemIcon from '@material-ui/icons/Redeem';
import { Screen } from '../types';

export default {
  Screen: ProductsScreen,
  Icon: RedeemIcon,
  name: 'Productos',
  path: '/products',
  root: true,
} as Screen;
